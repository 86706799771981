<script setup lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import BaseFilterPopover from '@/components/ui/BaseFilterPopover/BaseFilterPopover.vue'
import BaseFilterPopoverElement from '@/components/ui/BaseFilterPopover/BaseFilterPopoverElement.vue'
import { type Location, Roles } from '@/models'
import type { LocationsFilters } from '@/models/domain/location/api/Location.ts'
import { ChargePointStatus, WebSocketStatus } from '@/models/ocpp/enums.ts'
import { useApi } from '@/stores/api/api.ts'
import { useAuthStore } from '@/stores/auth'
import { getClassForStatusStore } from '@/utils/status.ts'

const filters = defineModel<LocationsFilters>('filters', {
  required: true
})

const { locations } = defineProps<{ locations: Location[] }>()

const { organizationEmails } = storeToRefs(useApi())
const { role, organizations: userOrganizations } = storeToRefs(useAuthStore())

const statuses = ref([
  { name: t('status.available'), status: ChargePointStatus.AVAILABLE },
  { name: t('status.unavailable'), status: ChargePointStatus.UNAVAILABLE },
  { name: t('status.incidence'), status: ChargePointStatus.FAULTED },
  { name: t('status.disconnected'), status: WebSocketStatus.DISCONNECTED }
])

const clearFilters = () => {
  filters.value = {
    ids: [],
    status: [],
    organizations: [],
    text: ''
  }
}
</script>

<template>
  <BaseFilterPopover :hideApply="true" @reset="clearFilters">
    <BaseFilterPopoverElement
      v-if="
        role.name === Roles.admin ||
        role.name === Roles.support ||
        (role.name === Roles.manager && userOrganizations.length > 1)
      "
      elementId="organizationsFilter"
      :label="t('detail.location.header.organization')"
    >
      <MultiSelect
        v-model="filters['organizations']"
        class="multi-select align-items-center"
        display="chip"
        filter
        optionLabel="name"
        optionValue="id"
        :maxSelectedLabels="3"
        :emptyFilterMessage="t('detail.organization.notFound')"
        :placeholder="t('detail.location.header.placeholder.organization')"
        :options="
          role.name === Roles.admin || role.name === Roles.support
            ? organizationEmails
            : userOrganizations
        "
      >
        <template #dropdownicon>
          <svg-icon name="organization" size="18" color="#9E9E9E" />
        </template>
        <template #option="slotProps">
          <div class="flex align-items-center">
            {{ slotProps.option.name }}
          </div>
        </template>
      </MultiSelect>
    </BaseFilterPopoverElement>
    <BaseFilterPopoverElement
      elementId="locationsFilter"
      :label="t('detail.location.header.location')"
    >
      <MultiSelect
        v-model="filters['ids']"
        class="multi-select align-items-center"
        :maxSelectedLabels="3"
        display="chip"
        :placeholder="t('detail.location.header.placeholder.location')"
        :emptyFilterMessage="t('detail.location.notFound')"
        :options="locations"
        optionLabel="name"
        optionValue="id"
        filter
      >
        <template #dropdownicon>
          <svg-icon name="location" size="18" color="#9E9E9E" />
        </template>
        <template #option="slotProps">
          <div class="flex align-items-center">
            <div class="text-color">{{ slotProps.option.name }}</div>
          </div>
        </template>
      </MultiSelect>
    </BaseFilterPopoverElement>
    <BaseFilterPopoverElement elementId="statusFilter" :label="t('detail.location.header.status')">
      <MultiSelect
        v-model="filters['status']"
        class="multi-select align-items-center"
        :maxSelectedLabels="4"
        display="chip"
        :placeholder="t('detail.location.header.placeholder.status')"
        :options="statuses"
        optionLabel="name"
        optionValue="status"
      >
        <template #option="slotProps">
          <div class="flex align-items-center">
            <div
              :class="`status status__${getClassForStatusStore(slotProps.option.status)} mr-1`"
            />
            <small
              class="font-bold"
              :class="`badgeStatus badgeStatus__${getClassForStatusStore(slotProps.option.status)}`"
              >{{ slotProps.option.name }}</small
            >
          </div>
        </template>
      </MultiSelect>
    </BaseFilterPopoverElement>
  </BaseFilterPopover>
</template>

<style scoped lang="scss"></style>
