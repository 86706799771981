import type { TransactionsFilters } from '@/models/domain/transaction/api/Transaction.ts'
import { formatDate } from '@/utils/formatDate.ts'

export const buildTransactionsSearchParams = (
  serverFilters: TransactionsFilters,
  currentPage?: number,
  rowsPerPage?: number
): string => {
  const searchParams = new URLSearchParams()

  if (rowsPerPage !== undefined) {
    searchParams.set('limit', rowsPerPage.toString())
  }

  if (currentPage !== undefined) {
    searchParams.set('offset', currentPage.toString())
  }

  if (
    Array.isArray(serverFilters.transactionDates) &&
    serverFilters.transactionDates[0] &&
    serverFilters.transactionDates[1]
  ) {
    const [start, end] = serverFilters.transactionDates.map(formatDate)
    searchParams.set('start', start)
    searchParams.set('end', end)
  }

  if (serverFilters.causeEnding.length > 0) {
    searchParams.set('causeEnding', serverFilters.causeEnding.join(','))
  }

  if (serverFilters.organizations && serverFilters.organizations.length > 0) {
    searchParams.set('organizations', serverFilters.organizations.join(','))
  }

  if (serverFilters.transactionType) {
    searchParams.set('transactionType', serverFilters.transactionType.toString())
  }

  return searchParams.toString()
}
