<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { array, date, number, object, string } from 'yup'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import ScheduleCreationDialog from '@/components/rates/ScheduleCreationDialog.vue'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import BaseInputNumber from '@/components/ui/BaseInputNumber.vue'
import { type Rate, Roles } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace.ts'
import type { IndexedSchedule, Schedule } from '@/models/domain/rate/api/Rate.ts'
import { ApiService } from '@/services/ApiService.ts'
import { useApi } from '@/stores/api/api.ts'
import { useAuthStore } from '@/stores/auth'
import { formatTime } from '@/utils/formatTime.ts'
import { dummySchedules } from '@/views/pages/rates/dummy-rates.ts'
import { CURRENCIES } from '@/utils/currencies.ts'
import { UnitOfMeasure } from '@/models/ocpp/enums.ts'

const { updatedRate, updating, toasting } = defineProps<{
  updatedRate?: Rate
  updating?: boolean
  toasting: ToastServiceMethods
}>()

const visible = defineModel<boolean>('visible')

const emit = defineEmits<{
  (e: 'refreshRates'): void
}>()

const { loading, organizationEmails } = storeToRefs(useApi())
const { role: userRole, organizations: userOrganizations } = useAuthStore()

const conflictingSchedules = ref<number[]>([])

const schema = toTypedSchema(
  object({
    alias: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    flatRate: number().required(() => t('validation.required')),
    schedules: array()
      .of(
        object({
          days: array()
            .of(number().required(() => t('validation.required')))
            .min(1)
            .max(7)
            .default([]),
          timeRange: object({
            start: date().required(() => t('validation.required')),
            end: date().required(() => t('validation.required'))
          })
            .default({ start: undefined, end: undefined })
            .required(() => t('validation.required')),
          cost: number().required(() => t('validation.required'))
        })
      )
      .default([])
      .test('no-overlapping-schedules', t('validation.scheduleConflict'), (schedules) => {
        conflictingSchedules.value = []
        let noConflict = true
        for (let i = 0; i < schedules.length; i++) {
          const scheduleA = schedules[i]
          const scheduleADays = new Set()

          for (const day of scheduleA.days) {
            scheduleADays.add(day)
          }

          for (let j = 0; j < schedules.length; j++) {
            if (i === j) continue
            const scheduleB = schedules[j]
            let out = true

            for (const day of scheduleB.days) {
              if (scheduleADays.has(day)) {
                out = false
                break
              }
            }

            if (out) continue

            // Borja: Why not compare dates directly? Because when you create the date object with
            // DatePicker, it also saves date and current seconds, even though as a user you are
            // picking a hh:mm time. So you may have situations where:
            // startA 10:00:05 > startB 10:00:04 but startA 10:00:32 < startB 10:00:43
            // or update a schedule and:
            // startA date 04/27 10:00:05 < startB date 04/28 09:00:00
            // We convert hours into minutes and sum the minutes for a comparison that doesn't take
            // seconds (or date) into account.
            const startA =
              scheduleA.timeRange.start.getHours() * 60 + scheduleA.timeRange.start.getMinutes()
            const endA =
              scheduleA.timeRange.end.getHours() * 60 + scheduleA.timeRange.end.getMinutes()
            const startB =
              scheduleB.timeRange.start.getHours() * 60 + scheduleB.timeRange.start.getMinutes()
            const endB =
              scheduleB.timeRange.end.getHours() * 60 + scheduleB.timeRange.end.getMinutes()

            if (
              (startA >= startB && startA < endB) ||
              (endA > startB && endA <= endB) ||
              (startA >= startB && endA <= endB)
            ) {
              conflictingSchedules.value.push(i)
              conflictingSchedules.value.push(j)
              noConflict = false
            }
          }
        }
        return noConflict
      }),
    organizationId: string()
      .uuid()
      .when((_, schema) =>
        userRole.name === Roles.admin ||
        (userRole.name === Roles.manager && userOrganizations.length > 1)
          ? schema.required(() => t('validation.required')).default('')
          : schema.notRequired()
      )
  })
)

const { defineField, handleSubmit, resetForm, errors, meta } = useForm({
  validationSchema: schema
})

const [alias] = defineField('alias')
const [flatRate] = defineField('flatRate')
const [schedules] = defineField('schedules')
const [organizationId] = defineField('organizationId')

const activeScheduleCreationDialog = ref<boolean>(false)
const activeScheduleUpdateDialog = ref<boolean>(false)
const updatedSchedule = ref<IndexedSchedule>()
const popup = ref()
const weekdays = ref([
  t('locale.days.sun'),
  t('locale.days.mon'),
  t('locale.days.tue'),
  t('locale.days.wed'),
  t('locale.days.thu'),
  t('locale.days.fri'),
  t('locale.days.sat')
])

const indexedSchedules = computed(
  () =>
    schedules?.value?.map((schedule, i) => ({
      ...schedule,
      id: i,
      conflict: conflictingSchedules.value.includes(i)
    })) || []
)

const handleCreateSchedule = () => {
  activeScheduleCreationDialog.value = true
}

const handleUpdateSchedule = (schedule) => {
  updatedSchedule.value = schedule
  activeScheduleUpdateDialog.value = true
}

const handleRemoveSchedule = async (event: Event, schedule) => {
  popup.value.showConfirmPopup(
    event,
    () => {
      schedules.value = indexedSchedules.value.reduce(
        (newSchedules: Schedule[], indexedSchedule) => {
          if (indexedSchedule.id === schedule.id) {
            return newSchedules
          } else {
            newSchedules.push({
              days: indexedSchedule.days,
              timeRange: indexedSchedule.timeRange,
              cost: indexedSchedule.cost
            })
            return newSchedules
          }
        },
        []
      )
    },
    undefined
  )
}

const pushSchedule = (newSchedule) => {
  if (schedules.value) {
    schedules.value = [...schedules.value, newSchedule]
  }
}

const updateSchedule = (newSchedule, id) => {
  if (schedules.value) {
    schedules.value = indexedSchedules.value.map((indexedSchedule) =>
      indexedSchedule.id === id
        ? newSchedule
        : {
            days: indexedSchedule.days,
            timeRange: indexedSchedule.timeRange,
            cost: indexedSchedule.cost
          }
    )
  }
}

const onSubmit = handleSubmit(async (values) => {
  try {
    loading.value = true
    if (updatedRate) {
      await ApiService.updateEntity(RouteNamespace.rates, updatedRate.id, {
        ...values,
        organizationId: values.organizationId ? values.organizationId : userOrganizations[0].id
      })
    } else {
      await ApiService.createEntity(RouteNamespace.rates, {
        ...values,
        organizationId: values.organizationId ? values.organizationId : userOrganizations[0].id
      })
    }
    toasting.add({
      group: 'success',
      severity: 'success',
      summary: updating
        ? t('detail.rate.notifications.updateSuccess')
        : t('detail.rate.notifications.createSuccess'),
      life: 3000
    })
    emit('refreshRates')
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
    toasting.add({
      group: 'error',
      severity: 'error',
      summary: updating
        ? t('detail.rate.notifications.updateError')
        : t('detail.rate.notifications.createError'),
      life: 3000
    })
  } finally {
    visible.value = false
    loading.value = false
  }
})

const handleCancel = () => {
  visible.value = false
}

watch(visible, () => {
  // TODO: Remove hardcoded dummySchedules when backend is implemented.
  if (updating) {
    resetForm({
      values: {
        ...updatedRate,
        schedules: dummySchedules,
        organizationId: updatedRate?.organization?.id
      }
    })
  } else resetForm()
})
</script>

<template>
  <BaseDialog
    v-model:visible="visible"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
        <p class="p-dialog-title">
          {{ updating ? t('detail.rate.dialog.edit') : t('detail.header.addRate') }}
        </p>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="rate" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div
        v-if="
          userRole.name === Roles.admin ||
          (userRole.name === Roles.manager && userOrganizations.length > 1)
        "
        class="flex flex-row"
      >
        <div class="field col-12">
          <label for="organizationId" class="font-family-light required">{{
            t('detail.rate.dialog.organization')
          }}</label>
          <Select
            v-model="organizationId"
            id="organizationId"
            aria-describedby="organizationId-help"
            class="h-3rem align-items-center"
            filter
            optionLabel="name"
            optionValue="id"
            :invalid="!!errors.organizationId"
            :options="userRole.name === Roles.admin ? organizationEmails : userOrganizations"
            :emptyMessage="t('detail.organization.notFound')"
            :placeholder="t('detail.rate.dialog.placeholder.organization')"
            :pt="{
              item: ({ context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#9E9E9E" />
              </div>
            </template>
          </Select>
          <small id="organizationId-help" class="p-error">
            {{ errors.organizationId }}
          </small>
        </div>
      </div>
      <div class="flex flex-column justify-content-between">
        <div class="flex flex-row justify-content-between">
          <div class="field col-6">
            <label class="font-family-light required" for="alias">{{
              t('detail.station.dialog.name')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="rate-alias" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                v-model="alias"
                id="alias"
                aria-describedby="alias-help"
                required="true"
                :disabled="userRole.name === Roles.support"
                :invalid="!!errors.alias"
                :placeholder="t('detail.rate.dialog.placeholder.name')"
              />
            </IconField>
            <small id="alias-help" class="p-error">
              {{ errors.alias }}
            </small>
          </div>
          <div class="field col-6">
            <label class="font-family-light required" for="flatRate">{{
              t('detail.rate.dialog.costBase')
            }}</label>
            <BaseInputNumber
              v-model="flatRate"
              id="flatRate"
              aria-describedby="flatRate-help"
              :disabled="userRole.name === Roles.support"
              :suffix="`${CURRENCIES.euro}`"
              :invalid="!!errors.flatRate"
              :min="0"
              :step="0.001"
              :placeholder="t('detail.rate.dialog.cost')"
            />
            <small id="flatRate-help" class="p-error">
              {{ errors.flatRate }}
            </small>
          </div>
        </div>
        <div class="field col-12 p-0 hidden">
          <DataTable :value="indexedSchedules" aria-describedby="schedules-help" scrollable>
            <template #header>
              <div class="flex align-items-center">
                <span class="font-bold text-2xl">{{ t('detail.rate.header.timetable') }}</span>
                <Button
                  v-if="userRole.name !== Roles.support"
                  class="button button-normal ml-3 w-2rem h-2rem"
                  rounded
                  v-tooltip.top="t('detail.rate.dialog.createRangeHour')"
                  @click="handleCreateSchedule"
                >
                  <template #icon>
                    <i class="font-bold pi pi-plus text-xs" />
                  </template>
                </Button>
              </div>
            </template>
            <template #empty>
              <div class="flex justify-content-center py-3">
                <span>{{ t('detail.rate.dialog.noSchedules') }}</span>
              </div>
            </template>
            <Column
              field="days"
              class="timetable__days"
              header-class="font-bold"
              :header="t('detail.rate.header.days')"
            >
              <template #body="slotProps">
                <div :class="`${slotProps.data.conflict ? 'conflict' : ''}`">
                  {{ slotProps.data.days.map((day) => weekdays[day]).join(', ') }}
                </div>
              </template>
            </Column>
            <Column
              field="timeRange.start"
              class="timetable__start"
              header-class="font-bold"
              :header="t('detail.rate.header.start')"
            >
              <template #body="slotProps">
                {{
                  `${formatTime(slotProps.data.timeRange.start.getHours())}:${formatTime(slotProps.data.timeRange.start.getMinutes())}`
                }}
              </template>
            </Column>
            <Column
              field="timeRange.end"
              class="timetable__end"
              header-class="font-bold"
              :header="t('detail.rate.header.end')"
            >
              <template #body="slotProps">
                {{
                  `${formatTime(slotProps.data.timeRange.end.getHours())}:${formatTime(slotProps.data.timeRange.end.getMinutes())}`
                }}
              </template>
            </Column>
            <Column
              field="cost"
              class="timetable__cost"
              header-class="font-bold"
              :header="t('detail.rate.header.cost')"
            >
              <template #body="slotProps">
                {{ `${slotProps.data.cost.toFixed(2)} ${CURRENCIES.euro} / ${UnitOfMeasure.KWH}` }}
              </template>
            </Column>
            <Column
              v-if="userRole.name !== Roles.support"
              class="timetable__header timetable__actions"
              header-class="table__header font-bold"
              :header="t('detail.header.actions')"
            >
              <template #body="slotProps">
                <div class="flex flex-row justify-content-center">
                  <Button
                    class="button button-normal mr-2"
                    rounded
                    v-tooltip.top="t('detail.rate.dialog.editRangeHour')"
                    @click="handleUpdateSchedule(slotProps.data)"
                  >
                    <template #icon>
                      <svg-icon name="edit" size="20" color="#626868" />
                    </template>
                  </Button>
                  <BaseConfirmDeletePopup ref="popup" />
                  <Button
                    class="button button-remove"
                    rounded
                    v-tooltip.top="t('detail.rate.dialog.removeRangeHour')"
                    @click="handleRemoveSchedule($event, slotProps.data)"
                  >
                    <template #icon>
                      <svg-icon name="trash" size="18" />
                    </template>
                  </Button>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <small id="schedules-help" class="p-error col-6">
        {{ errors.schedules }}
      </small>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog
          @cancel="handleCancel"
          @confirm="onSubmit"
          remove
          :disabled="!meta.valid || userRole.name === Roles.support"
        />
      </div>
    </template>
  </BaseDialog>
  <ScheduleCreationDialog
    v-model:visible="activeScheduleCreationDialog"
    :toasting="toasting"
    @push-schedule="pushSchedule"
  />
  <ScheduleCreationDialog
    v-model:visible="activeScheduleUpdateDialog"
    updating
    :updated-schedule="updatedSchedule"
    :toasting="toasting"
    @update-schedule="updateSchedule"
  />
</template>

<style scoped lang="scss">
::v-deep(.conflict::after) {
  content: ' *';
  color: var(--red);
}

::v-deep(.timetable__header) > div > span {
  margin: 0 auto;
}

::v-deep(.timetable__days) {
  width: 37.5%;
}

::v-deep(.timetable__start) {
  width: 11%;
}

::v-deep(.timetable__end) {
  width: 11%;
}

::v-deep(.timetable__cost) {
  width: 22.5%;
}

::v-deep(.timetable__actions) {
  width: 18%;
}
</style>
