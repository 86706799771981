export enum HeadersType {
  CONTENT_DISPOSITION = 'content-disposition'
}

export enum ContentType {
  CSV = 'text/csv',
  PDF = 'application/pdf'
}

export enum UriEndpoint {
  MAIL_PASSWORD = 'mail-password',
  RESOURCE = 'resource'
}
