<script setup lang="ts">
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { useForm } from 'vee-validate'
import { number, object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import BaseInputNumber from '@/components/ui/BaseInputNumber.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import { type Charger } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import type { FileUploadEvent } from '@/models/ui/FileUpload'
import { ApiService } from '@/services/ApiService'
import { useApi } from '@/stores/api/api'

const { updatedCharger, updatedChargerId, updating, toasting } = defineProps<{
  updatedCharger?: Partial<Charger>
  updatedChargerId?: string
  updating?: boolean
  toasting: ToastServiceMethods
}>()

const visible = defineModel<boolean>('visible')

const emit = defineEmits<{
  (e: 'refreshChargers'): void
  (e: 'addImage', newCharger: Charger): void
}>()

const { connectorTypes, loading, vendors } = storeToRefs(useApi())

const schema = toTypedSchema(
  object({
    connectors: number()
      .min(1, ({ min }) => t('validation.min', { min }))
      .required(() => t('validation.required'))
      .default(1),
    connectorType: string()
      .uuid()
      .required(() => t('validation.required'))
      .default(''),
    image: string()
      .max(150, ({ max }) => t('validation.max', { max }))
      .default(''),
    name: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    vendor: string()
      .uuid()
      .required(() => t('validation.required'))
      .default('')
  })
)

const { defineField, handleSubmit, resetForm, errors, meta } = useForm({
  validationSchema: schema
})

const [connectors] = defineField('connectors')
const [connectorType] = defineField('connectorType')
const [image] = defineField('image')
const [name] = defineField('name')
const [vendor] = defineField('vendor')

const isDeletedImage = ref(false)
const newImageFile = ref<File | null>(null)
const uploadedImage = ref('')

const onSubmit = handleSubmit(async (values) => {
  try {
    loading.value = true

    if (updatedChargerId) {
      const formData = new FormData()
      formData.append('connectors', values.connectors.toString())
      formData.append('connectorType', values.connectorType)
      formData.append('name', values.name)
      formData.append('vendor', values.vendor)
      if (newImageFile.value) formData.append('image', newImageFile.value)
      else if (isDeletedImage.value) formData.append('image', '')
      else formData.append('image', image.value || '')

      await ApiService.updateEntityWithResources<Charger>(
        `${RouteNamespace.chargers}/${updatedChargerId}`,
        formData
      )
    } else {
      const newCharger = await ApiService.createEntity<Charger>(RouteNamespace.chargers, values)

      emit('addImage', newCharger)
    }

    toasting.add({
      group: 'success',
      severity: 'success',
      summary: updating
        ? t('detail.charger.notifications.updateSuccess')
        : t('detail.charger.notifications.createSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while modifying chargers:', error)
    toasting.add({
      group: 'error',
      severity: 'error',
      summary: updating
        ? t('detail.charger.notifications.updateError')
        : t('detail.charger.notifications.createError'),
      life: 3000
    })
  } finally {
    visible.value = false
    emit('refreshChargers')
    loading.value = false
  }
})
const handleCancel = () => {
  visible.value = false
}
const handleUpload = (event: FileUploadEvent) => {
  const file = event.files && event.files[event.files.length - 1]
  if (file) {
    const reader = new FileReader()
    reader.onload = () => {
      newImageFile.value = file
      uploadedImage.value = URL.createObjectURL(file)
    }
    reader.readAsDataURL(file)
  }
  event.files.pop()
}
const removeImage = () => {
  isDeletedImage.value = true
  newImageFile.value = null
  uploadedImage.value = ''
}
const getChargerImage = async () => {
  try {
    const response = (await ApiService.readResourceByEntity(
      RouteNamespace.chargers,
      updatedChargerId || ''
    )) as Blob
    if (response instanceof Blob && response.size > 2) {
      // size is by default 2 when the image is not received
      uploadedImage.value = URL.createObjectURL(response)
    }
  } catch (error) {
    console.error(error)
  }
}
watch(visible, () => {
  isDeletedImage.value = false
  newImageFile.value = null
  uploadedImage.value = ''
  resetForm({
    values: {
      ...updatedCharger,
      connectorType: updatedCharger?.connectorType?.id,
      vendor: updatedCharger?.vendor?.id
    }
  })
  if (updatedChargerId) getChargerImage()
})
</script>

<template>
  {{ '' /* TODO: Error message layout integration */ }}
  <BaseDialog
    v-model:visible="visible"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
        <div v-if="updatedCharger" class="flex flex-row">
          <p class="p-dialog-title mr-1">{{ t('detail.charger.actions.update') }} -</p>
          <p class="p-dialog-title font-family-light font-italic">
            {{ updatedCharger['name'] || '' }}
          </p>
        </div>
        <p v-else class="p-dialog-title">{{ t('detail.charger.actions.create') }}</p>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="vendor-charger" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-row justify-content-between">
        <div class="field col-6">
          <label class="font-family-light required" for="name">{{
            t('detail.charger.dialog.name')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="vendor-charger" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="name"
              id="name"
              aria-describedby="name-help"
              required="true"
              :invalid="!!errors.name"
              :placeholder="t('detail.charger.dialog.placeholder.name')"
            />
          </IconField>
          <small id="name-help" class="p-error">
            {{ errors.name }}
          </small>
        </div>
        <div class="field col-6">
          <label class="font-family-light required" for="connectors">{{
            t('detail.charger.dialog.connectors')
          }}</label>
          <BaseInputNumber
            v-model="connectors"
            id="connectors"
            aria-describedby="connectors-help"
            :min="1"
            :max="27"
            :pt="{
              pcInputText: {
                root: {
                  disabled: true
                }
              }
            }"
          />
          <small id="connectors-help" class="p-error">
            {{ errors.connectors }}
          </small>
        </div>
      </div>
      <div class="flex flex-row align-items-center justify-content-between">
        <div class="field col-6">
          <label for="connectorType" class="required">{{
            t('detail.charger.dialog.connectorType')
          }}</label>
          <Select
            v-model="connectorType"
            id="connectorType"
            class="w-12 h-3rem align-items-center"
            aria-describedby="connectorType-help"
            :options="connectorTypes"
            optionLabel="connector"
            optionValue="id"
            :emptyMessage="t('detail.charger.dialog.placeholder.notFoundConnectorTypes')"
            :invalid="!!errors.connectorType"
            :placeholder="t('detail.charger.dialog.placeholder.connectorType')"
            :pt="{
              item: ({ context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
              </div>
            </template>
          </Select>
          <small id="connectorType-help" class="p-error">
            {{ errors.connectorType }}
          </small>
        </div>
        <div class="field col-6">
          <label for="vendor" class="required">{{ t('detail.charger.dialog.vendor') }}</label>
          <Select
            v-model="vendor"
            id="vendor"
            class="w-12 h-3rem align-items-center"
            aria-describedby="vendor-help"
            :options="vendors"
            optionLabel="name"
            optionValue="id"
            :emptyMessage="t('detail.charger.dialog.placeholder.notFoundVendors')"
            :invalid="!!errors.vendor"
            :placeholder="t('detail.charger.dialog.placeholder.vendor')"
            :pt="{
              item: ({ context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
              </div>
            </template>
          </Select>
          <small id="vendor-help" class="p-error">
            {{ errors.vendor }}
          </small>
        </div>
      </div>
      <div v-if="updating" class="flex flex-column">
        <div class="flex flex-row col-5 align-items-center">
          <label class="font-family-light" for="upload">{{
            t('detail.charger.dialog.image')
          }}</label>
        </div>
        <div class="flex flex-row h-5rem">
          <div class="flex flex-row col-8 justify-content-evenly align-items-center">
            <Avatar
              v-if="uploadedImage"
              class="border-1 border-100 avatar w-5rem h-5rem"
              shape="circle"
              :image="uploadedImage"
            />
            <Avatar
              v-else
              class="border-1 border-100 avatar w-5rem h-5rem"
              shape="circle"
              size="xlarge"
              icon="pi pi-bolt"
            />
            <div class="flex flex-column gap-2">
              <FileUpload
                class="button button-normal border-round-2xl w-full"
                mode="basic"
                name="demo[]"
                auto
                url="/api/upload"
                @select="handleUpload"
                accept="image/*"
                :maxFileSize="1000000"
                :chooseLabel="t('detail.settings.actions.uploadImage')"
                :pt="{
                  content: { class: 'hidden' },
                  buttonbar: { class: 'p-0 border-none' }
                }"
              />
              <Button
                class="button w-full"
                icon="pi pi-trash"
                rounded
                severity="danger"
                :label="t('detail.settings.actions.removeImage')"
                @click="removeImage"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog @cancel="handleCancel" @confirm="onSubmit" remove :disabled="!meta.valid" />
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
::v-deep(.avatar img) {
  object-fit: cover;
}
</style>
