import type { Ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import { t } from '@/common/i18n'
import { ContentType } from '@/config'
import { useApi } from '@/stores/api/api.ts'
import { RouteNamespace } from '@/models/common/RouteNameSpace.ts'
import type { TransactionsFilters } from '@/models/domain/transaction/api/Transaction.ts'
import { ApiService } from '@/services/ApiService.ts'
import { buildTransactionsSearchParams } from '@/views/pages/transactions/utils/buildTransactionsSearchParams.ts'

export function useDownloadTransactionsCsv(serverFilters: Ref<TransactionsFilters>) {
  const { loading } = storeToRefs(useApi())
  const toast = useToast()

  const downloadTransactionsCsv = async (locationId?: string) => {
    try {
      loading.value = true

      const searchParams = buildTransactionsSearchParams(serverFilters.value)
      const url = `${RouteNamespace.transactions}/download${locationId ? `/${locationId}` : ''}${searchParams ? `?${searchParams}` : ''}`

      await ApiService.downloadFile(url, ContentType.CSV)
    } catch (error) {
      console.error('Error downloading CSV:', error)
      toast.add({
        group: 'error',
        severity: 'error',
        summary: t('detail.transactions.notifications.errorDownload'),
        life: 5000
      })
    } finally {
      loading.value = false
    }
  }

  return { downloadTransactionsCsv }
}
