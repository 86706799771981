import { getToken, tokenType } from '@/config/axios'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { ApiService } from '@/services/ApiService'

enum TokenStatus {
  INVALID = 0,
  VALID = 1
}

const storageKeys = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken'
}

const decodeJWT = (token: string) => {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    )
    return JSON.parse(jsonPayload)
  } catch (error) {
    console.error('Error decoding JWT:', error)
    return null
  }
}
const isTokenExpired = (exp: number): boolean => {
  return exp * 1000 <= Date.now()
}
const storeTokens = (accessToken: string, refreshToken: string) => {
  sessionStorage.setItem(storageKeys.ACCESS_TOKEN, accessToken)
  sessionStorage.setItem(storageKeys.REFRESH_TOKEN, refreshToken)
}

const refreshAndStoreTokens = async (): Promise<TokenStatus> => {
  try {
    const newTokens = await ApiService.refreshTokens(RouteNamespace.refreshToken)
    const { accessToken, refreshToken } = newTokens.data
    storeTokens(accessToken, refreshToken)
    return TokenStatus.VALID
  } catch (error) {
    console.error('Error refreshing tokens:', error)
    return TokenStatus.INVALID
  }
}
export const checkValidToken = async (): Promise<TokenStatus> => {
  const accessToken = getToken(tokenType.ACCESS_TOKEN)
  const refreshToken = getToken(tokenType.REFRESH_TOKEN)
  const decodedAccessToken = accessToken ? decodeJWT(accessToken) : null
  const decodedRefreshToken = refreshToken ? decodeJWT(refreshToken) : null
  if (decodedAccessToken && !isTokenExpired(decodedAccessToken.exp)) return TokenStatus.VALID
  // Refresh token valid, trying to refresh tokens
  if (decodedRefreshToken && !isTokenExpired(decodedRefreshToken.exp)) {
    return await refreshAndStoreTokens()
  }
  return TokenStatus.INVALID // Expired tokens
}
