<script setup lang="ts">
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { boolean, object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import { Roles, type User } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { ApiService } from '@/services/ApiService'
import { useApi } from '@/stores/api/api'
import { useAuthStore } from '@/stores/auth'
import router from '@/router'

const { updatedUser, updatedUserId, updating, toasting } = defineProps<{
  updatedUser?: Partial<User>
  updatedUserId?: string
  updating?: boolean
  toasting: ToastServiceMethods
}>()

const visible = defineModel<boolean>('visible')

const emit = defineEmits<{
  (e: 'refreshUsers'): void
}>()

const { roles, loading, contractEmails, organizationEmails } = storeToRefs(useApi())
const { role: userRole, organizations: userOrganizations } = storeToRefs(useAuthStore())
const route = useRoute()

const rolesMap = computed(() => {
  return new Map(roles.value.map((role) => [role.id, role.name]))
})

// TODO: Error messages i18n
const schema = toTypedSchema(
  object({
    active: boolean().when((_, schema) =>
      updating
        ? schema.required(() => t('validation.required')).default(false)
        : schema.notRequired()
    ),
    contract: string().when('roleId', (roleId, schema) => {
      if (rolesMap.value.get(roleId[0]) === Roles.manager) {
        return schema
          .uuid(() => t('validation.required'))
          .required(() => t('validation.required'))
          .default('')
      } else {
        return schema.notRequired()
      }
    }),
    email: string()
      .email(() => t('validation.isEmail'))
      .max(50, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    name: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    organization: string().when('roleId', (roleId, schema) => {
      if (
        userRole.value.name === Roles.admin ||
        (userRole.value.name === Roles.manager && userOrganizations.value.length > 1)
      ) {
        switch (rolesMap.value.get(roleId[0])) {
          case Roles.admin:
          case Roles.manager:
          case Roles.support:
            return schema.notRequired()
          default:
            return schema
              .uuid(() => t('validation.required'))
              .required(() => t('validation.required'))
              .default('')
        }
      } else {
        return schema.notRequired()
      }
    }),
    phone: string()
      .max(50, ({ max }) => t('validation.max', { max }))
      .default(''),
    sendPasswordEmail: boolean().when((_, schema) =>
      updating
        ? schema.notRequired()
        : schema.required(() => t('validation.required')).default(false)
    ),
    surname: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .default(''),
    roleId: string()
      .uuid()
      .required(() => t('validation.required'))
  })
)

const { defineField, handleSubmit, resetForm, errors, meta, setFieldValue } = useForm({
  validationSchema: schema
})

const [active] = defineField('active')
const [contract] = defineField('contract')
const [email] = defineField('email')
const [name] = defineField('name')
const [organization] = defineField('organization')
const [phone] = defineField('phone')
const [sendPasswordEmail] = defineField('sendPasswordEmail')
const [surname] = defineField('surname')
const [roleId] = defineField('roleId')

const onSubmit = handleSubmit(async (values) => {
  try {
    loading.value = true
    if (updatedUserId) {
      await ApiService.updateEntity(RouteNamespace.users, updatedUserId, {
        ...values,
        organization:
          rolesMap.value.get(values.roleId) === Roles.admin ||
          rolesMap.value.get(values.roleId) === Roles.manager ||
          rolesMap.value.get(values.roleId) === Roles.support
            ? []
            : values.organization
              ? values.organization
              : userOrganizations.value[0].id
      })
    } else {
      await ApiService.createEntity(RouteNamespace.users, {
        ...values,
        organization:
          rolesMap.value.get(values.roleId) === Roles.admin ||
          rolesMap.value.get(values.roleId) === Roles.manager ||
          rolesMap.value.get(values.roleId) === Roles.support
            ? []
            : values.organization
              ? values.organization
              : userOrganizations.value[0].id
      })
    }
    toasting.add({
      group: 'success',
      severity: 'success',
      summary: updating
        ? t('detail.user.notifications.updateSuccess')
        : t('detail.user.notifications.createSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
    toasting.add({
      group: 'error',
      severity: 'error',
      summary: updating
        ? t('detail.user.notifications.updateError')
        : t('detail.user.notifications.createError'),
      life: 3000
    })
  } finally {
    visible.value = false
    emit('refreshUsers')
    loading.value = false
  }
})

const handleCancel = () => {
  visible.value = false
}

const resetParams = () => {
  if (route.query.active) router.replace({ query: { ...route.query, active: undefined } })
}

const filteredRoles = computed(() => {
  const adminFilter = () => true
  const managerFilter = (role: { name: string }) =>
    role['name'] !== Roles.admin &&
    role['name'] !== Roles.manager &&
    role['name'] !== Roles.support &&
    role['name'] !== Roles.technician
  const ownerFilter = (role: { name: string }) =>
    role['name'] !== Roles.admin &&
    role['name'] !== Roles.manager &&
    role['name'] !== Roles.owner &&
    role['name'] !== Roles.support &&
    role['name'] !== Roles.technician
  let filter

  switch (userRole.value.name) {
    case Roles.admin:
      filter = adminFilter
      break
    case Roles.manager:
      filter = managerFilter
      break
    case Roles.owner:
      filter = ownerFilter
      break
    default:
      filter = () => false
  }

  return roles.value.filter(filter).map((role) => ({
    label: t(`roles.${role['name']}`),
    value: role['id']
  }))
})

const resetOwnershipFields = () => {
  switch (rolesMap.value.get(roleId.value)) {
    case Roles.admin:
    case Roles.support:
      setFieldValue('organization', '')
      setFieldValue('contract', '')
      break
    case Roles.manager:
      setFieldValue('organization', '')
      break
    default:
      setFieldValue('contract', '')
      break
  }
}

watch(visible, () => {
  resetForm({
    values: {
      ...updatedUser,
      contract: updatedUser?.role?.name === Roles.manager ? updatedUser?.contract?.id : '',
      organization:
        updatedUser?.role?.name === Roles.admin ||
        updatedUser?.role?.name === Roles.manager ||
        updatedUser?.role?.name === Roles.support
          ? ''
          : updatedUser?.organizations?.[0]?.id,
      roleId: updatedUser?.role?.id
    }
  })
})
</script>

<template>
  {{ '' /* TODO: Error message layout integration */ }}
  <BaseDialog
    v-model:visible="visible"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
    @hide="resetParams"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
        <div v-if="updatedUser" class="flex flex-row">
          <p class="p-dialog-title mr-1">{{ t('detail.user.actions.update') }} -</p>
          <p class="p-dialog-title font-family-light font-italic">
            {{ updatedUser?.name || '' }}
            {{ updatedUser?.surname || '' }}
          </p>
        </div>
        <p v-else class="p-dialog-title">{{ t('detail.user.actions.create') }}</p>
      </div>
      <div class="absolute top-0 right-0 mt-4 mb-4 mr-3">
        <svg-icon name="user-face" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-row align-items-center justify-content-between">
        <div class="field col-6">
          <label for="roleId" class="required">{{ t('detail.user.header.role') }}</label>
          <Select
            @change="resetOwnershipFields"
            v-model="roleId"
            labelId="roleId"
            class="w-12 h-3rem align-items-center"
            aria-describedby="roleId-help"
            :options="filteredRoles"
            optionLabel="label"
            optionValue="value"
            :emptyMessage="t('detail.user.dialog.placeholder.notFoundRoles')"
            :invalid="!!errors.roleId"
            :placeholder="t('detail.user.dialog.placeholder.role')"
            :pt="{
              item: ({ context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
              </div>
            </template>
          </Select>
          <small id="roleId-help" class="p-error">
            {{ errors.roleId }}
          </small>
        </div>
        <div v-if="updating" class="flex flex-row col-5">
          <Checkbox v-model="active" inputId="active" :binary="true" />
          <label for="active" class="ml-2"> {{ t('detail.user.header.active') }} </label>
        </div>
        <div v-else class="flex flex-row col-5">
          <Checkbox v-model="sendPasswordEmail" inputId="sendPasswordEmail" :binary="true" />
          <label for="sendPasswordEmail" class="ml-2">
            {{ t('detail.user.header.activation') }}
          </label>
        </div>
      </div>
      <div v-if="userRole.name === Roles.admin" class="flex flex-row">
        <div class="field col-12">
          <label
            for="contract"
            :class="
              rolesMap.get(roleId) === Roles.manager
                ? 'font-family-light required'
                : 'font-family-light'
            "
            >{{ t('detail.user.dialog.contract') }}</label
          >
          <Select
            v-model="contract"
            labelId="contract"
            aria-describedby="contract-help"
            class="h-3rem align-items-center"
            display="chip"
            filter
            optionLabel="name"
            optionValue="id"
            :disabled="!roleId || rolesMap.get(roleId) !== Roles.manager"
            :emptyMessage="t('detail.contract.notFound')"
            :invalid="!!errors.contract"
            :options="contractEmails"
            :placeholder="
              rolesMap.get(roleId) === Roles.manager
                ? t('detail.user.dialog.placeholder.contract')
                : ''
            "
            :pt="{
              item: ({ context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#9E9E9E" />
              </div>
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div class="text-color">{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Select>
          <small id="contract-help" class="p-error">
            {{ errors.contract }}
          </small>
        </div>
      </div>
      <div
        v-if="
          userRole.name === Roles.admin ||
          (userRole.name === Roles.manager && userOrganizations.length > 1)
        "
        class="flex flex-row"
      >
        <div class="field col-12">
          <label
            for="organization"
            :class="
              !roleId ||
              rolesMap.get(roleId) === Roles.admin ||
              rolesMap.get(roleId) === Roles.manager ||
              rolesMap.get(roleId) === Roles.support
                ? 'font-family-light'
                : 'font-family-light required'
            "
            >{{ t('detail.user.dialog.organization') }}</label
          >
          <Select
            v-model="organization"
            labelId="organization"
            aria-describedby="organization-help"
            class="h-3rem align-items-center"
            display="chip"
            filter
            optionLabel="name"
            optionValue="id"
            :emptyMessage="t('detail.organization.notFound')"
            :disabled="
              !roleId ||
              rolesMap.get(roleId) === Roles.admin ||
              rolesMap.get(roleId) === Roles.manager ||
              rolesMap.get(roleId) === Roles.support
            "
            :invalid="!!errors.organization"
            :options="userRole.name === Roles.admin ? organizationEmails : userOrganizations"
            :placeholder="
              !roleId ||
              rolesMap.get(roleId) === Roles.admin ||
              rolesMap.get(roleId) === Roles.manager ||
              rolesMap.get(roleId) === Roles.support
                ? ''
                : t('detail.user.dialog.placeholder.organization')
            "
            :pt="{
              item: ({ context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#9E9E9E" />
              </div>
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div class="text-color">{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Select>
          <small id="organization-help" class="p-error">
            {{ errors.organization }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between p-0 col-12">
        <div class="field col-6">
          <label for="name" class="required">{{ t('detail.user.dialog.name') }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="user-face" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="name"
              id="name"
              type="text"
              aria-describedby="name-help"
              :invalid="!!errors.name"
              :placeholder="t('detail.user.dialog.placeholder.name')"
            />
          </IconField>
          <small id="name-help" class="p-error">
            {{ errors.name }}
          </small>
        </div>
        <div class="field col-6">
          <label for="surname">{{ t('detail.user.dialog.surname') }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="user" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="surname"
              id="surname"
              type="text"
              aria-describedby="surname-help"
              :invalid="!!errors.surname"
              :placeholder="t('detail.user.dialog.placeholder.surname')"
            />
          </IconField>
          <small id="surname-help" class="p-error">
            {{ errors.surname }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between">
        <div class="field col-6">
          <label for="phone">{{ t('detail.user.dialog.phone') }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="phone" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="phone"
              id="phone"
              type="text"
              aria-describedby="phone-help"
              :invalid="!!errors.phone"
              :placeholder="t('detail.user.dialog.placeholder.phone')"
            />
          </IconField>
          <small id="phone-help" class="p-error">
            {{ errors.phone }}
          </small>
        </div>
        <div class="field col-6">
          <label for="email" class="required">{{ t('detail.user.dialog.email') }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="mail" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="email"
              id="email"
              type="text"
              aria-describedby="email-help"
              :invalid="!!errors.email"
              :placeholder="t('detail.user.dialog.placeholder.email')"
            />
          </IconField>
          <small id="email-help" class="p-error">
            {{ errors.email }}
          </small>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog @cancel="handleCancel" @confirm="onSubmit" remove :disabled="!meta.valid" />
      </div>
    </template>
  </BaseDialog>
</template>
