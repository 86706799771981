<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import BaseGeoLocalization from '@/components/ui/BaseGeoLocalization.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import { type Localization, type Organization, Roles } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import type { FileUploadEvent } from '@/models/ui/FileUpload'
import router from '@/router'
import { ApiService } from '@/services/ApiService'
import { useApi } from '@/stores/api/api'
import { useAuthStore } from '@/stores/auth'
import { capitalizeString } from '@/utils/capitalize'

const { updatedOrganization, updatedOrganizationId, updating, toasting } = defineProps<{
  updatedOrganization?: Partial<Organization>
  updatedOrganizationId?: string
  updating?: boolean
  toasting: ToastServiceMethods
}>()

const visible = defineModel<boolean>('visible')

const emit = defineEmits<{
  (e: 'refreshOrganizations'): void
  (e: 'addImage', newOrganization: Organization): void
}>()

const { loading, contractEmails } = storeToRefs(useApi())
const { fetchOrganizationEmails } = useApi()
const { role: userRole, contract: userContract } = useAuthStore()
const route = useRoute()

const schema = toTypedSchema(
  object({
    address: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .default(''),
    city: string()
      .max(40, ({ max }) => t('validation.max', { max }))
      .transform((value) => capitalizeString(value))
      .default(''),
    contract: string()
      .uuid()
      .when((_, schema) =>
        userRole.name === Roles.admin
          ? schema.required(() => t('validation.required')).default('')
          : schema.notRequired()
      ),
    country: string()
      .max(25, ({ max }) => t('validation.max', { max }))
      .transform((value) => capitalizeString(value))
      .default(''),
    description: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .default(''),
    email: string()
      .email(() => t('validation.isEmail'))
      .max(100, ({ max }) => t('validation.max', { max }))
      .default(''),
    imageProfile: string()
      .max(150, ({ max }) => t('validation.max', { max }))
      .default(''),
    name: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    phone: string()
      .max(25, ({ max }) => t('validation.max', { max }))
      .default(''),
    postcode: string()
      .max(25, ({ max }) => t('validation.max', { max }))
      .default(''),
    province: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .transform((value) => capitalizeString(value))
      .default('')
  })
)

const { defineField, handleSubmit, resetForm, errors, meta } = useForm({
  validationSchema: schema
})

const [address] = defineField('address')
const [city] = defineField('city')
const [contract] = defineField('contract')
const [country] = defineField('country')
const [email] = defineField('email')
const [imageProfile] = defineField('imageProfile')
const [name] = defineField('name')
const [phone] = defineField('phone')
const [postcode] = defineField('postcode')
const [province] = defineField('province')

const isDeletedImage = ref(false)
const newImageFile = ref<File | null>(null)
const uploadedImage = ref('')

const modifyAddress = (input: string) => {
  address.value = input
}

const updateAddress = (e: Localization) => {
  address.value = e.address_line1
  city.value = e.city
  country.value = e.country
  postcode.value = e.postcode
  province.value = e.county
}

const onSubmit = handleSubmit(async (values) => {
  try {
    loading.value = true

    if (updatedOrganizationId) {
      const formData = new FormData()
      formData.append('name', values.name)
      formData.append('address', values.address)
      formData.append('city', values.city)
      formData.append('province', values.province)
      formData.append('contract', values.contract ? values.contract : userContract.id)
      formData.append('country', values.country)
      formData.append('postcode', values.postcode)
      formData.append('email', values.email)
      formData.append('phone', values.phone)
      if (newImageFile.value) formData.append('imageProfile', newImageFile.value)
      else if (isDeletedImage.value) formData.append('imageProfile', '')
      else formData.append('imageProfile', imageProfile.value || '')

      await ApiService.updateEntityWithResources<Organization>(
        `${RouteNamespace.organizations}/${updatedOrganizationId}`,
        formData
      )
    } else {
      const newOrganization = await ApiService.createEntity<Organization>(
        RouteNamespace.organizations,
        {
          ...values,
          contract: values.contract ? values.contract : userContract.id
        }
      )

      emit('addImage', newOrganization)
    }

    if (userRole.name === Roles.admin) await fetchOrganizationEmails()

    toasting.add({
      group: 'success',
      severity: 'success',
      summary: updating
        ? t('detail.organization.notifications.updateSuccess')
        : t('detail.organization.notifications.createSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
    toasting.add({
      group: 'error',
      severity: 'error',
      summary: updating
        ? t('detail.organization.notifications.updateError')
        : t('detail.organization.notifications.createError'),
      life: 3000
    })
  } finally {
    visible.value = false
    emit('refreshOrganizations')
    loading.value = false
  }
})
const handleCancel = () => {
  visible.value = false
}
const handleUpload = (event: FileUploadEvent) => {
  const file = event.files && event.files[event.files.length - 1]
  if (file) {
    const reader = new FileReader()
    reader.onload = () => {
      newImageFile.value = file
      uploadedImage.value = URL.createObjectURL(file)
    }
    reader.readAsDataURL(file)
  }
  event.files.pop()
}
const removeImage = () => {
  isDeletedImage.value = true
  newImageFile.value = null
  uploadedImage.value = ''
}
const resetParams = () => {
  if (route.query.active) router.replace({ query: { ...route.query, active: undefined } })
}
const getImageProfile = async () => {
  try {
    const response = (await ApiService.readResourceByEntity(
      RouteNamespace.organizations,
      updatedOrganizationId || ''
    )) as Blob
    if (response instanceof Blob && response.size > 2) {
      // size is by default 2 when the image is not received
      uploadedImage.value = URL.createObjectURL(response)
    }
  } catch (error) {
    console.log(error)
  }
}
watch(visible, () => {
  isDeletedImage.value = false
  newImageFile.value = null
  uploadedImage.value = ''
  resetForm({ values: { ...updatedOrganization, contract: updatedOrganization?.contract?.id } })
  if (updatedOrganizationId) getImageProfile()
})
</script>

<template>
  {{ '' /* TODO: Error message layout integration */ }}
  <BaseDialog
    v-model:visible="visible"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
    @hide="resetParams"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
        <div v-if="updatedOrganization" class="flex flex-row">
          <p class="p-dialog-title mr-1">{{ t('detail.organization.actions.update') }} -</p>
          <p class="p-dialog-title font-family-light font-italic">
            {{ updatedOrganization['name'] || '' }}
          </p>
        </div>
        <p v-else class="p-dialog-title">{{ t('detail.organization.actions.create') }}</p>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="organization" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div v-if="userRole.name === Roles.admin" class="flex flex-row">
        <div class="field col-12">
          <label for="contract" class="font-family-light required">{{
            t('detail.user.dialog.contract')
          }}</label>
          <Select
            v-model="contract"
            id="contract"
            aria-describedby="contract-help"
            class="h-3rem align-items-center"
            display="chip"
            filter
            optionLabel="name"
            optionValue="id"
            :emptyMessage="t('detail.contract.notFound')"
            :invalid="!!errors.contract"
            :options="contractEmails"
            :placeholder="t('detail.organization.dialog.placeholder.contract')"
            :pt="{
              item: ({ context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#9E9E9E" />
              </div>
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div class="text-color">{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Select>
          <small id="contract-help" class="p-error">
            {{ errors.contract }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between p-0 col-12">
        <div class="field col-12">
          <label class="font-family-light required" for="name">{{
            t('detail.organization.header.name')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="organization" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="name"
              id="name"
              type="text"
              aria-describedby="name-help"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.name"
              :placeholder="t('detail.organization.dialog.placeholder.name')"
            />
          </IconField>
          <small id="name-help" class="p-error">
            {{ errors.name }}
          </small>
        </div>
      </div>
      <div class="flex flex-row p-0 col-12">
        <div class="field col-12">
          <BaseGeoLocalization
            :address="updatedOrganization ? updatedOrganization.address : ''"
            :disabled="userRole.name === Roles.support"
            :errors="errors.address"
            :placeholder="t('detail.location.dialog.placeholder.address')"
            @modify-address="modifyAddress"
            @select-address="updateAddress"
          />
          <small id="address-help" class="p-error">
            {{ errors.address }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between p-0 col-12">
        <div class="field col-6">
          <label class="font-family-light" for="city">{{
            t('detail.organization.header.city')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="city" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="city"
              id="city"
              aria-describedby="city-help"
              type="text"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.city"
              :placeholder="t('detail.organization.dialog.placeholder.city')"
            />
          </IconField>
          <small id="city-help" class="p-error">
            {{ errors.city }}
          </small>
        </div>
        <div class="field col-6">
          <label class="font-family-light" for="province">{{
            t('detail.organization.header.province')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="province" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="province"
              id="province"
              aria-describedby="province-help"
              type="text"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.province"
              :placeholder="t('detail.organization.dialog.placeholder.province')"
            />
          </IconField>
          <small id="province-help" class="p-error">
            {{ errors.province }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between p-0 col-12">
        <div class="field col-6">
          <label class="font-family-light" for="postcode">{{
            t('detail.organization.header.postcode')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="postcode" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="postcode"
              id="postcode"
              aria-describedby="postcode-help"
              type="text"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.postcode"
              :placeholder="t('detail.organization.dialog.placeholder.postcode')"
            />
          </IconField>
          <small id="postcode-help" class="p-error">
            {{ errors.postcode }}
          </small>
        </div>
        <div class="field col-6">
          <label class="font-family-light" for="country">{{
            t('detail.organization.header.country')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="country" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="country"
              id="country"
              aria-describedby="country-help"
              type="text"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.country"
              :placeholder="t('detail.organization.dialog.placeholder.country')"
            />
          </IconField>
          <small id="country-help" class="p-error">
            {{ errors.country }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between p-0 col-12">
        <div class="field col-6">
          <label class="font-family-light" for="email">{{
            t('detail.organization.header.email')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="mail" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="email"
              id="email"
              aria-describedby="email-help"
              type="text"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.email"
              :placeholder="t('detail.organization.dialog.placeholder.email')"
            />
          </IconField>
          <small id="email-help" class="p-error">
            {{ errors.email }}
          </small>
        </div>
        <div class="field col-6">
          <label class="font-family-light" for="phone">{{
            t('detail.organization.header.phone')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="phone" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="phone"
              id="phone"
              aria-describedby="phone-help"
              type="text"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.phone"
              :placeholder="t('detail.organization.dialog.placeholder.phone')"
            />
          </IconField>
          <small id="phone-help" class="p-error">
            {{ errors.phone }}
          </small>
        </div>
      </div>
      <div v-if="updating" class="flex flex-column">
        <div class="flex flex-row col-5 align-items-center">
          <label class="font-family-light" for="upload">{{
            t('detail.organization.actions.upload')
          }}</label>
        </div>
        <div class="flex flex-row h-5rem">
          <div class="flex flex-row col-8 justify-content-evenly align-items-center">
            <Avatar
              class="border-1 border-100 avatar w-5rem h-5rem"
              shape="circle"
              :image="uploadedImage"
            >
              <template #default>
                <svg-icon v-if="!uploadedImage" name="user" size="48" color="#9E9E9E" />
              </template>
            </Avatar>
            <div class="flex flex-column gap-2">
              <FileUpload
                class="button button-normal border-round-2xl w-full"
                mode="basic"
                name="demo[]"
                auto
                url="/api/upload"
                @select="handleUpload"
                accept="image/*"
                :disabled="userRole.name === Roles.support"
                :maxFileSize="1000000"
                :chooseLabel="t('detail.settings.actions.uploadImage')"
                :pt="{
                  content: { class: 'hidden' },
                  buttonbar: { class: 'p-0 border-none' }
                }"
              />
              <Button
                class="button w-full"
                icon="pi pi-trash"
                rounded
                severity="danger"
                :disabled="userRole.name === Roles.support"
                :label="t('detail.settings.actions.removeImage')"
                @click="removeImage"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog
          @cancel="handleCancel"
          @confirm="onSubmit"
          remove
          :disabled="!meta.valid || userRole.name === Roles.support"
        />
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="css">
::v-deep(.avatar img) {
  object-fit: cover;
}
</style>
