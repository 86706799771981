import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import { t } from '@/common/i18n'
import { ContentType } from '@/config'
import type { Transaction } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace.ts'
import { TypeDocument } from '@/models/domain/transaction/api/Transaction.ts'
import { ApiService } from '@/services/ApiService.ts'
import { useApi } from '@/stores/api/api.ts'

export function useDownloadTransactionReport() {
  const { loading } = storeToRefs(useApi())
  const toast = useToast()

  const downloadTransactionReport = async (transaction: Transaction, type: TypeDocument) => {
    try {
      loading.value = true
      await ApiService.downloadFile(
        `${RouteNamespace.files}/${type}/${transaction['id']}`,
        ContentType.PDF
      )
    } catch (error) {
      console.error('Error downloading transaction report:', error)
      toast.add({
        group: 'error',
        severity: 'error',
        summary: t('detail.transactions.notifications.errorDownload'),
        life: 5000
      })
    } finally {
      loading.value = false
    }
  }

  return { downloadTransactionReport }
}
