<script setup lang="ts">
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import { type Vendor } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import type { FileUploadEvent } from '@/models/ui/FileUpload'
import { ApiService } from '@/services/ApiService'
import { useApi } from '@/stores/api/api'

const { updatedVendor, updatedVendorId, updating, toasting } = defineProps<{
  updatedVendor?: Partial<Vendor>
  updatedVendorId?: string
  updating?: boolean
  toasting: ToastServiceMethods
}>()

const visible = defineModel<boolean>('visible')

const emit = defineEmits<{
  (e: 'refreshVendors'): void
  (e: 'addImage', newVendor: Vendor): void
}>()

const { loading } = storeToRefs(useApi())

const schema = toTypedSchema(
  object({
    image: string()
      .max(150, ({ max }) => t('validation.max', { max }))
      .default(''),
    name: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required'))
  })
)

const { defineField, handleSubmit, resetForm, errors, meta } = useForm({
  validationSchema: schema
})

const [image] = defineField('image')
const [name] = defineField('name')

const isDeletedImage = ref(false)
const newImageFile = ref<File | null>(null)
const uploadedImage = ref('')

const onSubmit = handleSubmit(async (values) => {
  try {
    loading.value = true

    if (updatedVendorId) {
      const formData = new FormData()
      formData.append('name', values.name)
      if (newImageFile.value) formData.append('image', newImageFile.value)
      else if (isDeletedImage.value) formData.append('image', '')
      else formData.append('image', image.value || '')

      await ApiService.updateEntityWithResources<Vendor>(
        `${RouteNamespace.vendors}/${updatedVendorId}`,
        formData
      )
    } else {
      const newVendor = await ApiService.createEntity<Vendor>(RouteNamespace.vendors, values)
      emit('addImage', newVendor)
    }

    toasting.add({
      group: 'success',
      severity: 'success',
      summary: updating
        ? t('detail.vendor.notifications.updateSuccess')
        : t('detail.vendor.notifications.createSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while modifying vendors:', error)
    toasting.add({
      group: 'error',
      severity: 'error',
      summary: updating
        ? t('detail.vendor.notifications.updateError')
        : t('detail.vendor.notifications.createError'),
      life: 3000
    })
  } finally {
    visible.value = false
    emit('refreshVendors')
    loading.value = false
  }
})
const handleCancel = () => {
  visible.value = false
}
const handleUpload = (event: FileUploadEvent) => {
  const file = event.files && event.files[event.files.length - 1]
  if (file) {
    const reader = new FileReader()
    reader.onload = () => {
      newImageFile.value = file
      uploadedImage.value = URL.createObjectURL(file)
    }
    reader.readAsDataURL(file)
  }
  event.files.pop()
}
const removeImage = () => {
  isDeletedImage.value = true
  newImageFile.value = null
  uploadedImage.value = ''
}
const getVendorImage = async () => {
  try {
    const response = (await ApiService.readResourceByEntity(
      RouteNamespace.vendors,
      updatedVendorId || ''
    )) as Blob
    if (response instanceof Blob && response.size > 2) {
      // size is by default 2 when the image is not received
      uploadedImage.value = URL.createObjectURL(response)
    }
  } catch (error) {
    console.error(error)
  }
}
watch(visible, () => {
  isDeletedImage.value = false
  newImageFile.value = null
  uploadedImage.value = ''
  resetForm({ values: updatedVendor })
  if (updatedVendorId) getVendorImage()
})
</script>

<template>
  {{ '' /* TODO: Error message layout integration */ }}
  <BaseDialog
    v-model:visible="visible"
    :closable="false"
    :style="{ width: '25vw', minWidth: '22rem', maxWidth: '33rem' }"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
        <div v-if="updatedVendor" class="flex flex-row">
          <p class="p-dialog-title mr-1">{{ t('detail.vendor.actions.update') }} -</p>
          <p class="p-dialog-title font-family-light font-italic">
            {{ updatedVendor['name'] || '' }}
          </p>
        </div>
        <p v-else class="p-dialog-title">{{ t('detail.vendor.actions.create') }}</p>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="vendor-charger" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-row justify-content-between">
        <div class="field col-12">
          <label class="font-family-light required" for="name">{{
            t('detail.vendor.dialog.name')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="vendor-charger" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="name"
              id="name"
              aria-describedby="name-help"
              required="true"
              :invalid="!!errors.name"
              :placeholder="t('detail.vendor.dialog.placeholder.name')"
            />
          </IconField>
          <small id="name-help" class="p-error">
            {{ errors.name }}
          </small>
        </div>
      </div>
      <div v-if="updating" class="flex flex-column">
        <div class="flex flex-row col-5 align-items-center">
          <label class="font-family-light" for="upload">{{
            t('detail.vendor.dialog.image')
          }}</label>
        </div>
        <div class="flex flex-row h-5rem">
          <div class="flex flex-row col-12 justify-content-evenly align-items-center">
            <Avatar
              v-if="uploadedImage"
              class="border-1 border-100 avatar w-5rem h-5rem"
              shape="circle"
              :image="uploadedImage"
            />
            <Avatar
              v-else
              class="border-1 border-100 avatar w-5rem h-5rem"
              shape="circle"
              size="xlarge"
              icon="pi pi-tag"
            />
            <div class="flex flex-column gap-2">
              <FileUpload
                class="button button-normal border-round-2xl w-full"
                mode="basic"
                name="demo[]"
                auto
                url="/api/upload"
                @select="handleUpload"
                accept="image/*"
                :maxFileSize="1000000"
                :chooseLabel="t('detail.settings.actions.uploadImage')"
                :pt="{
                  content: { class: 'hidden' },
                  buttonbar: { class: 'p-0 border-none' }
                }"
              />
              <Button
                class="button w-full"
                icon="pi pi-trash"
                rounded
                severity="danger"
                :label="t('detail.settings.actions.removeImage')"
                @click="removeImage"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog @cancel="handleCancel" @confirm="onSubmit" remove :disabled="!meta.valid" />
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
::v-deep(.avatar img) {
  object-fit: cover;
}
</style>
