<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { dateFormat, t } from '@/common/i18n'
import BaseFilterPopover from '@/components/ui/BaseFilterPopover/BaseFilterPopover.vue'
import BaseFilterPopoverElement from '@/components/ui/BaseFilterPopover/BaseFilterPopoverElement.vue'
import { Roles } from '@/models'
import type { TransactionsFilters } from '@/models/domain/transaction/api/Transaction.ts'
import { useApi } from '@/stores/api/api.ts'
import { useAuthStore } from '@/stores/auth'
import { getCauseEnding } from '@/utils/status.ts'

const filters = defineModel<TransactionsFilters>('filters', {
  required: true
})

const emit = defineEmits<{
  (e: 'apply'): void
}>()

const { organizationEmails } = storeToRefs(useApi())
const { role, organizations: userOrganizations } = storeToRefs(useAuthStore())
const causeEnding = getCauseEnding()
const transactionType = [
  { name: t('detail.transactions.type.all'), value: 0 },
  { name: t('detail.transactions.type.payment'), value: 1 },
  { name: t('detail.transactions.type.free'), value: 2 }
]

const clearDatesSelected = () => {
  filters.value.transactionDates = []
}

const clearFilters = () => {
  if (filters.value.organizations) {
    filters.value = {
      causeEnding: [],
      organizations: [],
      transactionDates: [],
      transactionType: null
    }
  } else {
    filters.value = {
      causeEnding: [],
      transactionDates: [],
      transactionType: null
    }
  }

  emit('apply')
}
</script>

<template>
  <BaseFilterPopover @apply="emit('apply')" @reset="clearFilters">
    <BaseFilterPopoverElement elementId="dates" :label="t('detail.transactions.header.dates')">
      <DatePicker
        inputId="dates"
        class="date-picker shadow-none"
        v-model="filters.transactionDates"
        showIcon
        selectionMode="range"
        :manualInput="false"
        :dateFormat
        :placeholder="t('detail.transactions.header.rangeDates')"
        @clear-click="clearDatesSelected"
        showButtonBar
        :maxDate="new Date()"
      />
    </BaseFilterPopoverElement>
    <BaseFilterPopoverElement
      v-if="
        filters.organizations &&
        (role.name === Roles.admin ||
          role.name === Roles.support ||
          (role.name === Roles.manager && userOrganizations.length > 1))
      "
      elementId="organizations"
      :label="t('detail.transactions.header.organization')"
    >
      <MultiSelect
        inputId="organizations"
        v-model="filters.organizations"
        display="chip"
        class="multi-select align-items-center"
        :maxSelectedLabels="6"
        :placeholder="t('detail.transactions.header.organization')"
        :emptyFilterMessage="t('detail.organization.notFound')"
        :options="
          role.name === Roles.admin || role.name === Roles.support
            ? organizationEmails
            : userOrganizations
        "
        optionLabel="name"
        optionValue="id"
        filter
      >
        <template #option="slotProps">
          <div class="flex align-items-center">
            <div class="text-color">{{ slotProps.option.name }}</div>
          </div>
        </template>
      </MultiSelect>
    </BaseFilterPopoverElement>
    <BaseFilterPopoverElement
      elementId="transactionType"
      :label="t('detail.transactions.transactionType')"
    >
      <Select
        inputId="transactionType"
        v-model="filters.transactionType"
        class="h-3rem align-items-center"
        :options="transactionType"
        optionLabel="name"
        optionValue="value"
        :placeholder="t('detail.transactions.transactionType')"
        :pt="{
          item: ({ context }) => ({
            class: context.selected ? 'bg-gray-300' : context.focused ? 'bg-gray-100' : undefined
          })
        }"
      />
    </BaseFilterPopoverElement>
    <BaseFilterPopoverElement
      elementId="causeEnding"
      :label="t('detail.transactions.header.cause_ending')"
    >
      <MultiSelect
        inputId="causeEnding"
        v-model="filters.causeEnding"
        display="chip"
        class="multi-select align-items-center"
        :maxSelectedLabels="6"
        :placeholder="t('detail.transactions.header.cause_ending')"
        :options="causeEnding.sort((a, b) => a.name.localeCompare(b.name))"
        optionLabel="name"
        optionValue="status"
      >
        <template #option="slotProps">
          <div class="flex align-items-center">
            <div class="text-color">{{ slotProps.option.name }}</div>
          </div>
        </template>
      </MultiSelect>
    </BaseFilterPopoverElement>
  </BaseFilterPopover>
</template>

<style scoped lang="scss"></style>
