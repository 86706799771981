<script setup lang="ts">
import { ref } from 'vue'
import { t } from '@/common/i18n'

const { hideApply } = defineProps<{ hideApply?: boolean }>()

const emit = defineEmits<{
  (e: 'apply'): void
  (e: 'reset'): void
}>()

const op = ref()

const toggle = (event) => {
  op.value.toggle(event)
}
</script>

<template>
  <Button
    class="filter-button"
    icon="pi pi-filter"
    severity="secondary"
    type="button"
    :label="t('detail.transactions.header.filters')"
    @click="toggle"
  />

  <Popover ref="op">
    <h3 class="text-lg mb-4">{{ t('detail.transactions.header.filters') }}</h3>
    <div class="flex flex-column w-20rem gap-4">
      <slot />
    </div>
    <div class="flex justify-content-between mt-4">
      <Button autofocus severity="secondary" @click="emit('reset')">{{
        t('actions.clear')
      }}</Button>
      <Button v-if="!hideApply" @click="emit('apply')">{{ t('actions.apply') }}</Button>
    </div>
  </Popover>
</template>

<style scoped lang="scss">
.filter-button {
  height: 40px;
}
</style>
