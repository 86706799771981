<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import BaseFilterPopover from '@/components/ui/BaseFilterPopover/BaseFilterPopover.vue'
import BaseFilterPopoverElement from '@/components/ui/BaseFilterPopover/BaseFilterPopoverElement.vue'
import { Roles } from '@/models'
import type { RatesFilters } from '@/models/domain/rate/api/Rate.ts'
import { useApi } from '@/stores/api/api.ts'
import { useAuthStore } from '@/stores/auth'

const filters = defineModel<RatesFilters>('filters', {
  required: true
})

const emit = defineEmits<{
  (e: 'apply'): void
}>()

const { organizationEmails } = storeToRefs(useApi())
const { role, organizations: userOrganizations } = storeToRefs(useAuthStore())

const clearFilters = () => {
  filters.value = {
    organizations: []
  }

  emit('apply')
}
</script>

<template>
  <BaseFilterPopover @apply="emit('apply')" @reset="clearFilters">
    <BaseFilterPopoverElement
      v-if="
        role.name === Roles.admin ||
        role.name === Roles.support ||
        (role.name === Roles.manager && userOrganizations.length > 1)
      "
      elementId="organizations"
      :label="t('detail.user.header.organization')"
    >
      <MultiSelect
        v-model="filters.organizations"
        class="multi-select align-items-center"
        display="chip"
        filter
        optionLabel="name"
        optionValue="id"
        :maxSelectedLabels="3"
        :emptyFilterMessage="t('detail.organization.notFound')"
        :placeholder="t('detail.user.header.placeholder.organization')"
        :options="
          role.name === Roles.admin || role.name === Roles.support
            ? organizationEmails
            : userOrganizations
        "
      >
        <template #dropdownicon>
          <svg-icon name="organization" size="18" color="#9E9E9E" />
        </template>
        <template #option="slotProps">
          <div class="flex align-items-center">
            {{ slotProps.option.name }}
          </div>
        </template>
      </MultiSelect>
    </BaseFilterPopoverElement>
  </BaseFilterPopover>
</template>

<style scoped lang="scss"></style>
