<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import clock from '@/assets/lottie/clock.json'
import { t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import TransactionsFiltersPopover from '@/components/transactions/TransactionsFiltersPopover.vue'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BasePaginator from '@/components/ui/BasePaginator.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import AppTopbar from '@/layout/AppTopbar.vue'
import { Roles } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace.ts'
import { type TransactionsFilters, TypeDocument } from '@/models/domain/transaction/api/Transaction'
import { UnitOfMeasure } from '@/models/ocpp/enums'
import { useAuthStore } from '@/stores/auth'
import { formatDuration } from '@/utils/formatDuration.ts'
import { evaluateStatus, getColorForCauseEnding } from '@/utils/status'
import { useDownloadTransactionsCsv } from '@/views/pages/transactions/composables/useDownloadTransactionsCsv.ts'
import { useDownloadTransactionReport } from '@/views/pages/transactions/composables/useDownloadTransactionReport.ts'
import { useGetTransactions } from '@/views/pages/transactions/composables/useGetTransactions.ts'

const { role } = storeToRefs(useAuthStore())
const currentPage = ref<number>(0)
const rowsPerPage = ref<number>(100)
const expandedRows = ref([])
const selectedProduct = ref()
const serverFilters = ref<TransactionsFilters>({
  causeEnding: [],
  organizations: [],
  transactionDates: [],
  transactionType: null
})

const { downloadTransactionsCsv } = useDownloadTransactionsCsv(serverFilters)
const { downloadTransactionReport } = useDownloadTransactionReport()
const { getTransactions, totalRecords, transactions } = useGetTransactions(
  RouteNamespace.transactions,
  serverFilters,
  currentPage,
  rowsPerPage
)

onMounted(async () => {
  await getTransactions()
})
</script>

<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex">
            <svg-icon name="organization" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.transactions') }}</span>
          </div>
          <Button
            icon="pi pi-download"
            rounded
            :label="t('detail.transactions.actions.export_csv')"
            :pt="{
              icon: {
                class: ['text-xl']
              }
            }"
            @click="() => downloadTransactionsCsv()"
          />
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex flex-row flex-wrap column-gap-4">
        <TransactionsFiltersPopover v-model:filters="serverFilters" @apply="getTransactions" />
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      v-model:expanded-rows="expandedRows"
      v-model:selection="selectedProduct"
      :value="transactions"
      editMode="row"
      :rows="rowsPerPage"
      dataKey="id"
      scrollable
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.transactions.notFound')" />
      </template>
      <Column expander class="table__expander" />
      <Column
        field="cost"
        :header="t('detail.transactions.header.cost')"
        header-class="font-bold"
        class="table__cost"
      >
        <template #body="slotProps">
          {{ `${slotProps['data']['cost'].toFixed(2)} ${slotProps['data']['currency']}` }}
        </template>
      </Column>
      <Column
        field="duration"
        :header="t('detail.transactions.header.duration')"
        header-class="font-bold"
        class="table__duration"
      >
        <template #body="slotProps">
          {{ formatDuration(slotProps['data']['duration']) }}
        </template>
      </Column>
      <Column
        field="energy"
        :header="t('detail.transactions.header.energySupplied')"
        header-class="font-bold"
        class="table__energy"
      >
        <template #body="slotProps">
          {{ `${slotProps['data']['energy']} ${UnitOfMeasure.KWH}` }}
        </template>
      </Column>
      <Column
        field="rfid"
        :header="t('detail.transactions.header.rfid')"
        header-class="table__header font-bold"
        rowStyleClass="bg-gray-100"
        class="table__rfid"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            {{
              slotProps['data']?.['card']?.['alias']
                ? `${slotProps['data']['card']['alias']} \n(${slotProps['data']['card']['idTag']})`
                : (slotProps['data']['idTag'] ?? '---')
            }}
          </div>
        </template>
      </Column>
      <Column
        field="station.alias"
        :header="t('detail.transactions.header.chargePoint')"
        header-class="table__header font-bold"
        rowStyleClass="bg-gray-100"
        class="table__station"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            {{ slotProps['data']['station']?.['alias'] ?? '---' }}
          </div>
        </template>
      </Column>
      <Column
        field="connector.connection"
        :header="t('detail.transactions.header.connector')"
        header-class="table__header font-bold"
        rowStyleClass="bg-gray-100"
        class="table__connection"
      >
        <template #body="slotProps">
          <div class="flex justify-content-center">
            <Tag :value="slotProps['data'].connector?.connection" rounded />
          </div>
        </template>
      </Column>
      <Column
        field="causeEnding"
        :header="t('detail.transactions.header.cause_ending')"
        header-class="table__header font-bold"
        class="table__cause"
      >
        <template #body="slotProps">
          <div class="flex justify-content-center">
            <Tag
              rounded
              :severity="getColorForCauseEnding(slotProps.data.causeEnding)"
              :value="evaluateStatus(slotProps['data']['causeEnding'])"
            />
          </div>
        </template>
      </Column>
      <Column
        v-if="role.name === Roles.admin"
        :header="t('detail.transactions.header.ticket')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              v-tooltip.top="t('detail.transactions.actions.download_ticket')"
              rounded
              class="button button-normal"
              :class="slotProps.data['ticket'].trim() === '' ? 'hidden' : ''"
              @click="() => downloadTransactionReport(slotProps.data, TypeDocument.TICKETS)"
            >
              <template #icon>
                <svg-icon name="invoice" size="16" color="#626868" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <Column
        v-if="role.name === Roles.admin"
        :header="t('detail.transactions.header.invoice')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              v-tooltip.top="t('detail.transactions.actions.download_invoice')"
              rounded
              class="button button-start"
              :class="slotProps.data['invoice'].trim() === '' ? 'hidden' : ''"
              @click="() => downloadTransactionReport(slotProps.data, TypeDocument.INVOICES)"
            >
              <template #icon>
                <svg-icon name="invoice" size="16" color="#ffffff" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <template #footer>
        <BasePaginator
          v-model:current-page="currentPage"
          v-model:rows-per-page="rowsPerPage"
          :totalRecords
          @refresh-data="getTransactions"
          @change="getTransactions"
        />
      </template>
      <template #expansion="slotProps">
        <div class="flex flex-row p-1 justify-content-around">
          <div class="flex flex-column">
            <div class="flex">
              <svg-icon name="clock" size="18" />
              <span class="font-bold ml-2"
                >{{ t('detail.transactions.header.completionDate') }}:</span
              >
              <span class="ml-2">{{
                new Date(slotProps['data']['createdAt']).toLocaleString()
              }}</span>
            </div>
          </div>
          <div class="flex flex-column h-2rem justify-content-between">
            <div class="flex">
              <svg-icon name="contract" size="18" color="#9E9E9E"></svg-icon>
              <span class="font-bold font-bold ml-2">{{
                t('detail.transactions.header.contract')
              }}</span>
              <span class="ml-2">{{ slotProps.data.contract?.reference || 'N/A' }}</span>
            </div>
          </div>
          <div class="flex flex-column h-2rem justify-content-between">
            <div class="flex">
              <svg-icon name="location" color="#9E9E9E" size="18"></svg-icon>
              <span class="font-bold ml-2">{{ t('detail.transactions.header.location') }}</span>
              <span class="ml-2">{{ slotProps.data.station?.location?.name ?? '---' }}</span>
            </div>
          </div>
        </div>
      </template>
    </DataTable>
  </div>
  <BasePushNotification group="error" icon-name="restart" color="#EA2839" />
</template>
<style scoped lang="scss">
::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__expander) {
  width: 4%;
}

::v-deep(.table__cost) {
  width: 8%;
}

::v-deep(.table__start) {
  width: 13%;
}

::v-deep(.table__duration) {
  width: 10%;
}

::v-deep(.table__rfid) {
  width: 8%;
}

::v-deep(.table__energy) {
  width: 12%;
}

::v-deep(.table__station) {
  width: 18%;
}

::v-deep(.table__connection) {
  width: 5%;
}

::v-deep(.table__cause) {
  width: 20%;
}

::v-deep(.table__actions) {
  width: 6%;
}
</style>
