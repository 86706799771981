import { defineStore } from 'pinia'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { ApiService } from '@/services/ApiService'
import type { State } from '@/stores/api/types/state'
import type { Charger, Vendor } from '@/models'

export const useApi = defineStore('api', {
  state: (): State => ({
    totalTransactions: 0,
    totalUsers: 0,
    totalCards: 0,
    loading: false,
    chargers: [],
    connectorTypes: [],
    transactions: [],
    roles: [],
    contractEmails: [],
    organizationEmails: [],
    vendors: []
  }),
  getters: {
    getChargers: (state) => state.chargers,
    getConnectorTypes: (state) => state.connectorTypes,
    getTransactions: (state) => state.transactions,
    getTotalTransactions: (state) => state.totalTransactions,
    getTotalUsers: (state) => state.totalUsers,
    getTotalCards: (state) => state.totalCards,
    getRoles: (state) => state.roles,
    getLoading: (state) => state.loading,
    getContractEmails: (state) => state.contractEmails,
    getOrganizationEmails: (state) => state.organizationEmails,
    getVendors: (state) => state.vendors
  },
  actions: {
    async fetchChargers() {
      try {
        const chargers = await ApiService.readAllEntities<Charger>(RouteNamespace.chargers)
        this.chargers = await Promise.all(
          chargers.map(async (charger) => {
            try {
              const imageBlob = (await ApiService.readResourceByEntity(
                RouteNamespace.chargers,
                charger.id
              )) as Blob
              let imageUrl = ''
              if (imageBlob?.size > 2) {
                imageUrl = URL.createObjectURL(imageBlob)
              }
              return {
                ...charger,
                image: imageUrl
              }
            } catch (error) {
              console.error(
                `Error obteniendo la imagen del cargador ${(charger as Charger).id}:`,
                error
              )
              return {
                ...(charger as Charger),
                image: ''
              }
            }
          })
        )
        return this.chargers
      } catch (error) {
        console.error('Error al obtener los cargadores con imágenes:', error)
        throw error
      }
    },
    async fetchTransactions() {
      this.transactions = await ApiService.readAllEntities(RouteNamespace.transactions)
      return this.transactions
    },
    async fetchRoles() {
      this.roles = await ApiService.readAllEntities(RouteNamespace.roles)
      return this.roles
    },
    async fetchConnectorTypes() {
      this.connectorTypes = await ApiService.readAllEntities(RouteNamespace.connectorsTypes)
      return this.connectorTypes
    },
    async fetchContractEmails() {
      this.contractEmails = await ApiService.readAllEntities(
        `${RouteNamespace.contracts}/${RouteNamespace.emails}`
      )
      return this.contractEmails
    },
    async fetchOrganizationEmails() {
      this.organizationEmails = await ApiService.readAllEntities(
        `${RouteNamespace.organizations}/${RouteNamespace.emails}`
      )
      return this.organizationEmails
    },
    //TODO Unify methods vendors and charger in same method getting entity, type and namespace
    async fetchVendors() {
      try {
        const vendors = await ApiService.readAllEntities<Vendor>(RouteNamespace.vendors)
        this.vendors = await Promise.all(
          vendors.map(async (vendor) => {
            try {
              const imageBlob = (await ApiService.readResourceByEntity(
                RouteNamespace.vendors,
                vendor.id
              )) as Blob
              let imageUrl = ''
              if (imageBlob?.size > 2) {
                imageUrl = URL.createObjectURL(imageBlob)
              }
              return {
                ...vendor,
                image: imageUrl
              }
            } catch (error) {
              console.error(
                `Error obteniendo la imagen del cargador ${(vendor as Vendor).id}:`,
                error
              )
              return {
                ...(vendor as Vendor),
                image: ''
              }
            }
          })
        )
        return this.chargers
      } catch (error) {
        console.error('Error al obtener los cargadores con imágenes:', error)
        throw error
      }
    }
  }
})
