<script setup lang="ts">
import { onMounted, useTemplateRef, watch } from 'vue'
import { useRouter } from 'vue-router'
import L, { type Map } from 'leaflet'
import { t } from '@/common/i18n'
import type { Location, Station } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { ChargePointStatus, StationStatus, WebSocketStatus } from '@/models/ocpp/enums'
import { attribution, UPDATED_COLORS, UPDATED_STATES } from '@/models/ui/Map'
import { useChargePointStatusStore, useChargerConnectionStatusStore } from '@/stores/ocpp'

const { isDraggable, locations, zoom } = defineProps<{
  isDraggable?: boolean
  locations: Location[]
  zoom?: number
}>()

const router = useRouter()
const chargePointStatusStore = useChargePointStatusStore()
const chargerConnectionStatusStore = useChargerConnectionStatusStore()

const locationsMap = useTemplateRef('locations-map')

let leafletMap: Map | undefined

const latLng = L.latLng(41, 0)
const leafletControl = new L.Control({
  position: 'bottomright'
})
const tiles = L.tileLayer(import.meta.env.VITE_OPENSTREETMAP, {
  maxZoom: 19,
  attribution
})

const getSvgIcon = (color: string) => {
  return `<?xml version="1.0"?>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12,2.01C8.14,2.01,5.01,5.15,5.01,9c0,3.957,4.395,10.373,6.215,12.631c0.4,0.496,1.151,0.496,1.55,0 C14.595,19.373,18.99,12.957,18.99,9C18.99,5.15,15.86,2.01,12,2.01z M11,17v-5H9l4-7v5h2L11,17z"
            fill="${color}" stroke="${color}" stroke-width="0"/>
  </svg>`
}

const createClusterIcon = (cluster) => {
  return new L.DivIcon({
    html: `<div><span>${cluster.getChildCount()}</span></div>`,
    className: 'marker-cluster',
    iconSize: new L.Point(40, 40)
  })
}

const markers = L.markerClusterGroup({
  iconCreateFunction: createClusterIcon,
  chunkedLoading: true,
  spiderfyOnMaxZoom: false,
  disableClusteringAtZoom: 18,
  showCoverageOnHover: false,
  maxClusterRadius: 50,
  spiderLegPolylineOptions: {
    opacity: 0
  }
})

const getIconColor = (stations: Station[]) => {
  let color = UPDATED_COLORS[WebSocketStatus.DISCONNECTED]

  for (const station of stations) {
    const connectionStatus = chargerConnectionStatusStore.chargersConnectionStatus[station.cpId]
    if (!connectionStatus || connectionStatus === WebSocketStatus.DISCONNECTED) continue

    const stateValue = chargePointStatusStore.chargePointsStatuses[`${station.cpId}:0`]?.status
    if (stateValue === ChargePointStatus.FAULTED) {
      color = UPDATED_COLORS[stateValue]
      break
    } else if (stateValue === ChargePointStatus.AVAILABLE) {
      color = UPDATED_COLORS[stateValue]
      break
    } else if (stateValue === ChargePointStatus.UNAVAILABLE) {
      color = UPDATED_COLORS[stateValue]
    }
  }

  return L.divIcon({
    className: 'marker',
    html: getSvgIcon(color),
    iconSize: [28, 28],
    iconAnchor: [12, 24],
    popupAnchor: [0, -26]
  })
}

const getLocationStatusesSum = (stations: Station[]) => {
  const count = {
    [ChargePointStatus.AVAILABLE]: 0,
    [ChargePointStatus.FAULTED]: 0,
    [ChargePointStatus.UNAVAILABLE]: 0,
    [WebSocketStatus.DISCONNECTED]: 0
  }

  for (const station of stations) {
    const connectionStatus = chargerConnectionStatusStore.chargersConnectionStatus[station.cpId]
    const stationStatus = chargePointStatusStore.chargePointsStatuses[`${station.cpId}:0`]?.status

    if (
      !connectionStatus ||
      connectionStatus === WebSocketStatus.DISCONNECTED ||
      !(stationStatus in count)
    ) {
      count[WebSocketStatus.DISCONNECTED]++
    } else {
      count[stationStatus]++
    }
  }

  return count
}

const getTotalStatusesSum = () => {
  const totalCount = {
    [ChargePointStatus.AVAILABLE]: 0,
    [ChargePointStatus.FAULTED]: 0,
    [ChargePointStatus.UNAVAILABLE]: 0,
    [WebSocketStatus.DISCONNECTED]: 0
  }

  for (const location of locations) {
    for (const [key, value] of Object.entries(getLocationStatusesSum(location.stations))) {
      if (value > 0) totalCount[key]++
    }
  }

  return totalCount
}

const createLegendRow = (state: string, label: string, count: number) => `
  <div class="w-11rem pl-3 pr-3 text-gray-700">
      <div class="flex flex-row justify-content-between p-1 mb-1">
        <div class="flex flex-row align-items-center mt-2 mb-1">
          <i class="status status__${state}"></i>
          <span class="font-bold ml-2">${label}</span>
        </div>
        <div class="mt-2">${count}</div>
      </div>
  </div>`

const createHTMLLegend = () => {
  const labelMap = {
    [ChargePointStatus.AVAILABLE]: `${t('status.available')}`,
    [ChargePointStatus.FAULTED]: `${t('status.incidence')}`,
    [ChargePointStatus.UNAVAILABLE]: `${t('status.unavailable')}`,
    [WebSocketStatus.DISCONNECTED]: `${t('status.disconnected')}`
  }
  const legendRows = Object.entries(getTotalStatusesSum())
    .map(([key, value]) => {
      return createLegendRow(UPDATED_STATES[key], labelMap[key], value)
    })
    .join('')

  return `<div class="bg-white border-round-xl">${legendRows}</div>`
}

const changeLegend = () => {
  const legend = leafletControl.getContainer()
  if (legend) legend.innerHTML = createHTMLLegend()
}

const htmlPopup = (location: Location) => {
  const countedStates = getLocationStatusesSum(location.stations)
  let state = UPDATED_STATES[WebSocketStatus.DISCONNECTED]

  for (const [key, value] of Object.entries(countedStates)) {
    if (key === ChargePointStatus.FAULTED && value > 0) {
      state = UPDATED_STATES[key]
      break
    } else if (key === ChargePointStatus.AVAILABLE && value > 0) {
      state = UPDATED_STATES[key]
      break
    } else if (key === ChargePointStatus.UNAVAILABLE && value > 0) {
      state = UPDATED_STATES[key]
    }
  }

  const div = L.DomUtil.create('div', 'cursor-pointer text-gray-600')

  div.innerHTML = `
      <div class="flex flex-column align-items-center">
          <div class="flex flex-row">
              <div class="status status__${state}"></div>
              <span class="font-bold ml-2">${location.name}</span>
          </div>
      </div>
      <div class="p-2">
          <div class="flex flex-row">
              <span>${location.stations.length} ${t('chargePoints')}</span>
          </div>
          <div class="flex flex-row">
              <span>${countedStates[WebSocketStatus.DISCONNECTED]} ${t('status.disconnected')}</span>
          </div>
          <div class="flex flex-row">
              <span>${countedStates[StationStatus.AVAILABLE]} ${t('status.available')}</span>
          </div>
          <div class="flex flex-row">
              <span>${countedStates[StationStatus.UNAVAILABLE]} ${t('status.unavailable')}</span>
          </div>
          <div class="flex flex-row">
              <span>${countedStates[StationStatus.FAULTED]} ${t('status.incidence')}</span>
          </div>
      </div>
  `

  div.addEventListener('click', () => {
    router.push({ path: `${RouteNamespace.locations}/${location.id}` })
  })

  return div
}

const clickPan = (e: L.LeafletEvent) => {
  if (leafletMap && e.target) leafletMap.panTo((e.target as L.Marker).getLatLng())
}

const createNewsHTMLPopups = () => {
  markers.clearLayers()
  locations.forEach((location) => {
    const icon = getIconColor(location.stations)
    const marker = L.marker([location.lat, location.lon], { icon, draggable: isDraggable })
      .bindPopup(htmlPopup(location))
      .on('click', clickPan)
    markers.addLayer(marker)
  })
}

const updateMap = () => {
  if (leafletMap) {
    leafletMap.eachLayer((layer) => leafletMap?.removeLayer(layer))
    leafletMap.addLayer(tiles)
    const div = L.DomUtil.create('div', 'legend')
    leafletControl.onAdd = () => {
      div.innerHTML = createHTMLLegend()
      return div
    }
    leafletControl.addTo(leafletMap)
    createNewsHTMLPopups()
    leafletMap.addLayer(markers)
  }
}

const initMap = () => {
  leafletMap?.invalidateSize()
  leafletMap?.remove()
  leafletMap = L.map(locationsMap.value || '', { center: latLng, zoom: zoom })
  updateMap()
}

/**
 * TODO
 * Cuando se desconecta un cargador y vuelve a conectar cualquier estado que tuviera antes de la desconexión, lo ignora y
 * le pone available --> El cargador esta mandando mal el estado del conector 0, lo manda como available
 * En el nodo Save variables de OCPP (en la parte de BootNotification)
 */

watch(
  () => locations,
  () => {
    updateMap()
  }
)

watch(
  [
    () => chargePointStatusStore.chargePointsStatuses,
    () => chargerConnectionStatusStore.chargersConnectionStatus
  ],
  () => {
    changeLegend()
    createNewsHTMLPopups()
  },
  { deep: true }
)

onMounted(initMap)
</script>

<template>
  <div id="map" ref="locations-map" class="map my-hidden animate-duration-500" />
</template>

<style scoped lang="scss">
#map {
  height: 60vh;
}

.legend-area {
  border-radius: 10px;
  padding: 5em !important;
  background: var(--lightGray) !important;
}

.leaflet-container {
  z-index: 0;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadein {
  animation: fadein 250ms linear;
}

.fadeout {
  animation: fadeout 1510ms linear;
}
</style>
