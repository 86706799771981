import { type Ref, ref } from 'vue'
import { storeToRefs } from 'pinia'
import type {
  TransactionResponse,
  TransactionsFilters
} from '@/models/domain/transaction/api/Transaction.ts'
import { ApiService } from '@/services/ApiService.ts'
import { useApi } from '@/stores/api/api.ts'
import { useTransactions } from '@/stores/api/transactions.ts'
import { buildTransactionsSearchParams } from '@/views/pages/transactions/utils/buildTransactionsSearchParams.ts'

export function useGetTransactions(
  endpoint: string,
  serverFilters: Ref<TransactionsFilters>,
  currentPage: Ref<number>,
  rowsPerPage: Ref<number>
) {
  const { loading } = storeToRefs(useApi())
  const { transactions } = storeToRefs(useTransactions())
  const totalRecords = ref<number>(transactions.value.length)

  const getTransactions = async () => {
    try {
      loading.value = true

      const searchParams = buildTransactionsSearchParams(
        serverFilters.value,
        currentPage.value,
        rowsPerPage.value
      )

      const url = searchParams ? `${endpoint}/?${searchParams}` : endpoint

      const response = await ApiService.readAllEntities<TransactionResponse>(url)
      transactions.value = response['transactions']
      totalRecords.value = response['totalRecords']
    } catch (error) {
      console.error('Error retrieving transactions:', error)
    } finally {
      loading.value = false
    }
  }

  return { getTransactions, totalRecords, transactions }
}
