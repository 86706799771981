<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import type { DataTableRowClickEvent } from 'primevue/datatable'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import clock from '@/assets/lottie/clock.json'
import { t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import LocationCreationDialog from '@/components/locations/LocationCreationDialog.vue'
import StationCreationDialog from '@/components/stations/StationCreationDialog.vue'
import BaseBadge from '@/components/ui/BaseBadge.vue'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BasePaginator from '@/components/ui/BasePaginator.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import { socket } from '@/config/socket'
import AppTopbar from '@/layout/AppTopbar.vue'
import {
  type Coordinate,
  type Location,
  type Rate,
  Roles,
  type Station,
  type StationUpdateDTO,
  type Transaction
} from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { StationStatusMap } from '@/models/domain/location/enums'
import type { StationsResponse } from '@/models/domain/station/api/Station'
import type { ListStationsStatus } from '@/models/domain/station/component/ListStationsStatus'
import { type TransactionsFilters, TypeDocument } from '@/models/domain/transaction/api/Transaction'
import {
  ResetType,
  StationStatus,
  UnitOfMeasure,
  WebSocketStatus as ChargerWsStatus
} from '@/models/ocpp/enums'
import type { StatusNotificationMapped } from '@/models/ocpp/StatusNotification'
import router from '@/router'
import { ApiService } from '@/services/ApiService'
import { useApi } from '@/stores/api/api'
import { useAuthStore } from '@/stores/auth'
import { useChargePointStatusStore, useChargerConnectionStatusStore } from '@/stores/ocpp'
import { CURRENCIES } from '@/utils/currencies.ts'
import { calculateCO2Saved, calculateFuelSaved } from '@/utils/energyConverter.ts'
import { formatDuration } from '@/utils/formatDuration.ts'
import {
  evaluateStatus,
  getClassForStatusStore,
  getColorForCauseEnding,
  getStatusTranslationStore
} from '@/utils/status'
import { useDownloadTransactionsCsv } from '@/views/pages/transactions/composables/useDownloadTransactionsCsv.ts'
import { useDownloadTransactionReport } from '@/views/pages/transactions/composables/useDownloadTransactionReport.ts'
import { useGetTransactions } from '@/views/pages/transactions/composables/useGetTransactions.ts'
import TransactionsFiltersPopover from '@/components/transactions/TransactionsFiltersPopover.vue'

const toast = useToast()
const { loading } = storeToRefs(useApi())
const { role: userRole } = storeToRefs(useAuthStore())
const stations = ref<Station[]>([])
const rates = ref<Rate[]>([])
const stats = ref([])
const popup = ref()
const totalStations = ref<number>(0)
const totalConnectors = ref<number>(0)
const rowsPerStationsPage = ref<number>(10)
const currentStationsPage = ref<number>(0)
const activeCreationDialog = ref<boolean>(false)
const activeUpdateDialog = ref<boolean>(false)
const activeUpdateLocationDialog = ref<boolean>(false)
const coords = ref<Coordinate[]>([])
const location = ref<Partial<Location>>()
const locationLat = ref<number>(0)
const locationLon = ref<number>(0)
const alias = ref<string>('')
const updatedStation = ref<StationUpdateDTO>()
const updatedStationId = ref<string>('')
const tabIndex = ref<string>('0')
const allStationsStatus = ref<ListStationsStatus>({})
const stationStatus = ref<number>(0)
const route = useRoute()
const locationId = route.params.id as string
const confirm = useConfirm()
const chargePointStatusStore = useChargePointStatusStore()
const chargerConnectionStatusStore = useChargerConnectionStatusStore()

const transactionsCurrentPage = ref<number>(0)
const transactionRowsPerPage = ref<number>(10)
const expandedRows = ref([])
const selectedProduct = ref()
const serverFilters = ref<TransactionsFilters>({
  causeEnding: [],
  transactionDates: [],
  transactionType: null
})

const { downloadTransactionsCsv } = useDownloadTransactionsCsv(serverFilters)
const { downloadTransactionReport } = useDownloadTransactionReport()
const {
  getTransactions,
  totalRecords: totalTransactions,
  transactions
} = useGetTransactions(
  `${RouteNamespace.transactions}/${locationId}/location`,
  serverFilters,
  transactionsCurrentPage,
  transactionRowsPerPage
)

const getLocation = async () => {
  try {
    const response = await ApiService.readEntity<Location>(
      `${RouteNamespace.locations}/${locationId}`
    )
    location.value = {
      address: response.address,
      city: response.city,
      country: response.country,
      description: response.description,
      energySources: response.energySources,
      name: response.name,
      organization: response.organization,
      postcode: response.postcode,
      province: response.province
    }
    locationLat.value = response.lat
    locationLon.value = response.lon
    alias.value = response.name
  } catch (error) {
    console.error('Error retrieving charge points:', error)
  }
}

const getStations = async () => {
  try {
    loading.value = true
    const result = await ApiService.readAllEntities<StationsResponse>(
      `${RouteNamespace.stations}/${locationId}/location?limit=${rowsPerStationsPage.value}&offset=${currentStationsPage.value}`
    )
    stations.value = result['stations']
    totalStations.value = result['totalRecords']
  } catch (error) {
    console.error('Error retrieving charge points:', error)
  } finally {
    loading.value = false
  }
}

const getNewStation = async () => {
  await getStations()
  initializeListStationStatus()
  socket.emit('reload')
}

const getRates = async () => {
  try {
    rates.value = await ApiService.readAllEntities<Rate>(
      `${RouteNamespace.rates}/${locationId}/${RouteNamespace.location}`
    )
  } catch (error) {
    console.error('Error retrieving rates:', error)
  }
}

const handleUpdateLocation = () => {
  activeUpdateLocationDialog.value = true
}

const handleUpdate = (row: Station) => {
  updatedStation.value = {
    alias: row.alias,
    chargerId: row.charger.id,
    connectors: row.connector.map((conn) => {
      return {
        id: conn.id,
        connectorTypeId: conn.connectorType?.id,
        maxPower: conn.maxPower
      }
    }),
    lat: row.lat,
    lon: row.lon,
    paidService: row.paidService,
    rateId: row.rateId,
    serialNumber: row.serialNumber
  }
  updatedStationId.value = row.id
  stationStatus.value = row.status
  activeUpdateDialog.value = true
}

const confirmReset = (event: Event, cpId: string) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t('actions.confirmRemove'),
    icon: 'pi pi-exclamation-triangle',
    rejectProps: {
      label: t('no'),
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: t('yes')
    },
    accept: () => {
      handleOCPPReset(RouteNamespace.reset, cpId)
    },
    reject: () => {
      console.log('Rejecting operation')
    }
  })
}

const handleOCPPReset = async (route: string, cpId: string) => {
  try {
    loading.value = true
    const payload = {
      cpId,
      type: ResetType.HARD
    }
    await ApiService.createEntity(`${RouteNamespace.ocpp}/${route}`, payload)
    loading.value = false
  } catch (error) {
    console.error('Error resetting station:', error)
  } finally {
    loading.value = false
  }
}

const handleDetail = (rowClickEvent: DataTableRowClickEvent) => {
  router.push({
    name: RouteNamespace.connectors,
    params: { station: rowClickEvent['data']['id'], name: rowClickEvent['data']['alias'] }
  })
}

const handleAddItem = () => {
  activeCreationDialog.value = true
}

const actionsRemoveStation = async (id: string) => {
  try {
    loading.value = true
    await ApiService.deleteEntity(`${RouteNamespace.stations}/${id}`)
    toast.add({
      group: 'success',
      severity: 'success',
      summary: t('detail.station.notifications.deleteSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error deleting station:', error)
  } finally {
    await getStations()
    loading.value = false
  }
}

const handleRemove = async (event: Event, id: string) => {
  popup.value.showConfirmPopup(
    event,
    async () => {
      loading.value = true
      await actionsRemoveStation(id)
      loading.value = false
    },
    undefined
  )
}

const initializeListStationStatus = () => {
  try {
    stations.value.forEach((st) => {
      const cpId = st.cpId
      if (cpId) {
        const status = chargePointStatusStore.chargePointsStatuses[`${cpId}:0`]
        if (status) {
          allStationsStatus.value[cpId] = status.status
        } else {
          allStationsStatus.value[cpId] = StationStatusMap.DISCONNECTED
        }
      }
    })
  } catch (err) {
    console.log('Not found stations')
  }
}

const getNewStationState = (newValue: StatusNotificationMapped) => {
  allStationsStatus.value[newValue.cpId] =
    StationStatus[newValue.status.toUpperCase()] || StationStatusMap.DISCONNECTED
}

const getStats = async () => {
  try {
    stats.value = await ApiService.readAllEntities(
      `${RouteNamespace.locations}/${locationId}/${RouteNamespace.stats}`
    )
  } catch (error) {
    console.error('Error retrieving stats:', error)
  }
}

const getEnergy = (transactions: Transaction[]) =>
  transactions.reduce((acc, transaction) => acc + transaction.energy, 0).toFixed(2)

watch(
  () => chargePointStatusStore.chargePointsStatuses,
  (newStatuses) => {
    try {
      Object.values(newStatuses).forEach((status) => {
        const statusNotification = status as unknown as StatusNotificationMapped
        if (!statusNotification) return
        if (statusNotification.connectorId !== 0) return
        getNewStationState(status)
      })
    } catch (err) {
      console.error('Failed to register the new connector status', err)
    }
  },
  { deep: true }
)
watch(
  () => chargerConnectionStatusStore.chargersConnectionStatus,
  () => {
    initializeListStationStatus()
  },
  { deep: true }
)
onMounted(async () => {
  try {
    loading.value = true
    await Promise.all([getLocation(), getRates(), getStations(), getStats(), getTransactions()])
    coords.value = []
    totalConnectors.value = stations.value.reduce((acc: number, item: Station) => {
      return acc + item.connector.length
    }, 0)
  } catch (error) {
    console.error('Error retrieving charge points:', error)
  } finally {
    initializeListStationStatus()
    loading.value = false
  }
})
</script>

<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div>
          <Button
            class="text-500 hover:bg-teal-50"
            icon="pi pi-arrow-left"
            :label="t('dashboard.locations')"
            text
            as="router-link"
            :to="`/${RouteNamespace.locations}`"
          />
        </div>
        <div class="flex flex-row ml-2 justify-content-between align-items-center mb-3">
          <div class="flex align-items-center">
            <svg-icon name="station" size="24" color="#626868" />
            <span class="font-bold mr-2">{{ t('dashboard.location') }}</span>
            <span class="font-family-light font-italic">{{ location?.['name'] || '' }}</span>
          </div>
          <Button
            v-if="userRole.name !== Roles.support && userRole.name !== Roles.viewer"
            v-tooltip.top="t('detail.location.actions.update')"
            class="button button-normal shadow-1"
            rounded
            @click="handleUpdateLocation"
          >
            <template #icon>
              <svg-icon name="edit" size="18" color="#626868" />
            </template>
          </Button>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex flex-column mt-5" />
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <Tabs v-model:value="tabIndex">
      <TabList>
        <Tab value="0">
          <div class="flex justify-content-between align-items-center">
            <svg-icon name="vision" size="24" />
            <span>{{ t('detail.header.global') }}</span>
          </div>
        </Tab>
        <Tab value="1">
          <div class="flex justify-content-between align-items-center">
            <svg-icon name="station" size="24" />
            <span>{{ t('detail.header.chargePoints') }}</span>
            <Button
              v-if="userRole.name !== Roles.support && userRole.name !== Roles.viewer"
              v-tooltip.top="t('detail.station.actions.create')"
              rounded
              class="button button-normal ml-3"
              @click="handleAddItem"
            >
              <template #icon>
                <i class="font-bold pi pi-plus text-sm" />
              </template>
            </Button>
          </div>
        </Tab>
        <Tab value="2">
          <div class="flex justify-content-between align-items-center">
            <svg-icon name="charge" size="24" />
            <span>{{ t('detail.header.transactions') }}</span>
            <Button
              v-tooltip.top="t('detail.transactions.actions.export')"
              rounded
              class="button button-save ml-3"
              @click="() => downloadTransactionsCsv(locationId)"
            >
              <template #icon>
                <i class="font-bold pi pi-download text-sm" />
              </template>
            </Button>
          </div>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel value="0">
          <div class="flex flex-column justify-content-center h-5rem">
            <span class="font-bold">{{ t('detail.location.header.mensualStats') }}</span>
          </div>
          <div class="grid h-full">
            <div class="col-8 flex align-items-center">
              <div class="grid w-full">
                <div class="col-12 lg:col-3 md:col-6 sm:col-12">
                  <Card class="h-full bg-gray-100">
                    <template #title>
                      <span class="text-sm">{{ t('detail.location.header.energySupplied') }}</span>
                    </template>
                    <template #content>
                      <p class="text-xl">
                        {{ stats['energy']?.toFixed(2) || 0 }} {{ UnitOfMeasure.KWH }}
                      </p>
                    </template>
                  </Card>
                </div>

                <div class="col-12 lg:col-3 md:col-6 sm:col-12">
                  <Card class="h-full bg-gray-100">
                    <template #title>
                      <span class="text-sm">{{ t('detail.location.header.totalChargeTime') }}</span>
                    </template>
                    <template #content>
                      <p class="text-xl">{{ formatDuration(stats['duration']) || 0 }}</p>
                    </template>
                  </Card>
                </div>

                <div class="col-12 lg:col-3 md:col-6 sm:col-12">
                  <Card class="h-full bg-gray-100">
                    <template #title>
                      <span class="text-sm">{{ t('detail.location.header.totalSessions') }}</span>
                    </template>
                    <template #content>
                      <p class="text-xl">{{ stats['transactions'] || 0 }}</p>
                    </template>
                  </Card>
                </div>

                <div class="col-12 lg:col-3 md:col-6 sm:col-12">
                  <Card class="h-full bg-gray-100">
                    <template #title>
                      <span class="text-sm">{{ t('detail.location.header.totalIncome') }}</span>
                    </template>
                    <template #content>
                      <p class="text-xl">
                        {{ stats['cost']?.toFixed(2) || 0 }} {{ stats['currency'] }}
                      </p>
                    </template>
                  </Card>
                </div>
              </div>
            </div>
            <div class="col-4">
              <Card class="h-full bg-white energy-saved border-2">
                <template #title>
                  <div class="flex flex-column">
                    <div class="flex align-items-center justify-content-between">
                      <p class="text-lg m-0">{{ t('detail.station.greenEnvironment') }}</p>
                      <svg-icon name="ecological" size="28" color="#12B981" />
                    </div>
                    <p class="mt-2 text-sm">
                      {{ t('detail.station.greenEnvironmentMessage') }}
                    </p>
                  </div>
                </template>
                <template #content>
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex flex-column">
                      <p class="text-2xl m-0">{{ calculateCO2Saved(stats['energy'] || 0) }} KG</p>
                      <p class="text-sm m-0">{{ t('detail.station.greenEnvironmentSaving') }}</p>
                    </div>
                    <div class="flex flex-column">
                      <p class="text-2xl m-0">{{ calculateFuelSaved(stats['energy'] || 0) }} L</p>
                      <p class="text-sm m-0">
                        {{ t('detail.station.greenEnvironmentReplacingFuel') }}
                      </p>
                    </div>
                  </div>
                </template>
              </Card>
            </div>
          </div>
        </TabPanel>
        <TabPanel value="1">
          <div class="flex">
            <div class="col-12">
              <DataTable
                :value="stations"
                :rows="rowsPerStationsPage"
                selectionMode="single"
                :rowHover="true"
                dataKey="id"
                @rowClick="handleDetail($event)"
                scrollable
              >
                <template #empty>
                  <BaseLottieAnimation :icon="clock" :label="t('detail.station.notFound')" />
                </template>
                <Column
                  field="station"
                  :header="t('detail.station.header.charger')"
                  header-class="font-bold"
                  class="table__charger"
                >
                  <template #body="slotProps">{{ slotProps.index + 1 }}</template>
                </Column>
                <Column
                  field="model"
                  :header="t('detail.station.header.model')"
                  header-class="font-bold"
                  class="table__model"
                >
                  <template #body="slotProps">
                    <Tag
                      class="uppercase"
                      :value="slotProps.data['charger'].name"
                      rounded
                      severity="warn"
                    />
                  </template>
                </Column>
                <Column
                  sortable
                  field="alias"
                  :header="t('detail.station.header.name')"
                  header-class="font-bold"
                  class="table__alias"
                >
                  <template #body="slotProps">
                    {{ slotProps.data['alias'] }}
                  </template>
                </Column>
                <Column
                  field="rate"
                  :header="t('detail.station.header.rate')"
                  header-class="font-bold"
                  class="table__rate"
                >
                  <template #body="slotProps">
                    {{ slotProps.data['rate'] ? slotProps['data']['rate']['alias'] : '---' }}
                  </template>
                </Column>
                <Column
                  field="numberConnections"
                  :header="t('detail.station.header.connector')"
                  header-class="table__header font-bold"
                  class="table__connectors"
                >
                  <template #body="slotProps">
                    <div class="flex flex-row justify-content-center">
                      <Tag :value="slotProps.data['connector'].length" rounded />
                    </div>
                  </template>
                </Column>
                <Column
                  field="energy"
                  :header="t('detail.station.header.energySupplied')"
                  header-class="font-bold"
                  class="table__energy"
                >
                  <template #body="slotProps">
                    {{ `${getEnergy(slotProps.data.transactions)} ${UnitOfMeasure.KWH} ` }}
                  </template>
                </Column>
                <Column
                  field="status"
                  :header="t('detail.station.header.status')"
                  header-class="font-bold"
                  class="table__status"
                >
                  <template #body="slotProps">
                    <BaseBadge
                      rounded
                      outlined
                      :style-header="`badgeStatus badgeStatus__${getClassForStatusStore(
                        chargerConnectionStatusStore.chargersConnectionStatus[
                          slotProps.data.cpId
                        ] === ChargerWsStatus.CONNECTED
                          ? allStationsStatus[slotProps.data.cpId]
                          : StationStatusMap.DISCONNECTED
                      )}`"
                      :style-content="`status status__${getClassForStatusStore(
                        chargerConnectionStatusStore.chargersConnectionStatus[
                          slotProps.data.cpId
                        ] === ChargerWsStatus.CONNECTED
                          ? allStationsStatus[slotProps.data.cpId]
                          : StationStatusMap.DISCONNECTED
                      )}`"
                      :content="
                        getStatusTranslationStore(
                          chargerConnectionStatusStore.chargersConnectionStatus[
                            slotProps.data.cpId
                          ] === ChargerWsStatus.CONNECTED
                            ? allStationsStatus[slotProps.data.cpId]
                            : StationStatusMap.DISCONNECTED
                        )
                      "
                    />
                  </template>
                </Column>
                <Column
                  :header="t('detail.header.actions')"
                  header-class="table__header font-bold"
                  class="table__actions"
                >
                  <template #body="slotProps">
                    <div class="flex flex-row justify-content-center">
                      <Button
                        class="button button-normal mr-2"
                        v-tooltip.top="t('detail.station.actions.update')"
                        rounded
                        @click="handleUpdate(slotProps.data)"
                      >
                        <template #icon>
                          <svg-icon
                            :name="
                              userRole.name === Roles.support || userRole.name === Roles.viewer
                                ? 'vision'
                                : 'edit'
                            "
                            size="20"
                            color="#626868"
                          />
                        </template>
                      </Button>
                      <ConfirmPopup />
                      <Button
                        v-if="userRole.name !== Roles.viewer"
                        class="button button-warning"
                        v-tooltip.top="t('detail.station.actions.reset')"
                        rounded
                        :disabled="
                          !(
                            chargerConnectionStatusStore.chargersConnectionStatus[
                              slotProps.data.cpId
                            ] === ChargerWsStatus.CONNECTED
                          )
                        "
                        @click="confirmReset($event, slotProps.data.cpId)"
                      >
                        <template #icon>
                          <svg-icon name="restart" size="20" />
                        </template>
                      </Button>
                      <BaseConfirmDeletePopup ref="popup" />
                      <Button
                        v-if="userRole.name !== Roles.support && userRole.name !== Roles.viewer"
                        class="button button-remove ml-2"
                        v-tooltip.top="t('detail.station.actions.delete')"
                        rounded
                        @click="handleRemove($event, slotProps.data.id)"
                      >
                        <template #icon>
                          <svg-icon name="trash" size="18" />
                        </template>
                      </Button>
                    </div>
                  </template>
                </Column>
                <template #footer>
                  <BasePaginator
                    v-model:current-page="currentStationsPage"
                    v-model:rows-per-page="rowsPerStationsPage"
                    :totalRecords="totalStations"
                    @refresh-data="getStations"
                    @change="getStations"
                  />
                </template>
              </DataTable>
            </div>
          </div>
        </TabPanel>
        <TabPanel value="2">
          <div class="col-12">
            <div class="flex flex-row flex-wrap column-gap-4 mb-2">
              <TransactionsFiltersPopover
                v-model:filters="serverFilters"
                @apply="getTransactions"
              />
            </div>
            <DataTable
              v-model:expanded-rows="expandedRows"
              v-model:selection="selectedProduct"
              :value="transactions"
              editMode="row"
              :rows="transactionRowsPerPage"
              dataKey="id"
              scrollable
            >
              <template #empty>
                <BaseLottieAnimation :icon="clock" :label="t('detail.transactions.notFound')" />
              </template>
              <Column expander class="table__expander" />
              <Column
                field="cost"
                :header="t('detail.transactions.header.cost')"
                header-class="font-bold"
                class="table__cost"
              >
                <template #body="slotProps">
                  {{ `${slotProps['data']['cost'].toFixed(2)} ${slotProps['data']['currency']}` }}
                </template>
              </Column>
              <Column
                field="duration"
                :header="t('detail.transactions.header.duration')"
                header-class="font-bold"
                class="table__duration"
              >
                <template #body="slotProps">
                  {{ formatDuration(slotProps['data']['duration']) }}
                </template>
              </Column>
              <Column
                field="energy"
                :header="t('detail.transactions.header.energySupplied')"
                header-class="font-bold"
                class="table__energy--transactions"
              >
                <template #body="slotProps">
                  {{ `${slotProps['data']['energy']} ${UnitOfMeasure.KWH}` }}
                </template>
              </Column>
              <Column
                field="rfid"
                :header="t('detail.transactions.header.rfid')"
                header-class="table__header font-bold"
                rowStyleClass="bg-gray-100"
                class="table__rfid"
              >
                <template #body="slotProps">
                  <div class="flex flex-row justify-content-center">
                    {{
                      slotProps['data']?.['card']?.['alias']
                        ? `${slotProps['data']['card']['alias']} \n(${slotProps['data']['card']['idTag']})`
                        : (slotProps['data']['idTag'] ?? '---')
                    }}
                  </div>
                </template>
              </Column>
              <Column
                field="energy"
                :header="t('detail.transactions.header.chargePoint')"
                header-class="table__station font-bold"
                rowStyleClass="bg-gray-100"
                class="table__station"
              >
                <template #body="slotProps">
                  <div class="flex flex-row justify-content-center">
                    {{ slotProps['data']['station']?.['alias'] ?? '---' }}
                  </div>
                </template>
              </Column>
              <Column
                field="connector.connection"
                :header="t('detail.transactions.header.connector')"
                header-class="table__header font-bold"
                rowStyleClass="bg-gray-100"
                class="table__connection"
              >
                <template #body="slotProps">
                  <div class="flex justify-content-center">
                    <Tag :value="slotProps['data'].connector?.connection" rounded />
                  </div>
                </template>
              </Column>
              <Column
                field="causeEnding"
                :header="t('detail.transactions.header.cause_ending')"
                header-class="table__header font-bold"
                class="table__cause"
              >
                <template #body="slotProps">
                  <div class="flex justify-content-center">
                    <Tag
                      rounded
                      :severity="getColorForCauseEnding(slotProps.data.causeEnding)"
                      :value="evaluateStatus(slotProps['data']['causeEnding'])"
                    />
                  </div>
                </template>
              </Column>
              <Column
                v-if="userRole.name === Roles.admin"
                :header="t('detail.transactions.header.ticket')"
                header-class="table__header font-bold"
                class="transactions"
              >
                <template #body="slotProps">
                  <div class="flex flex-row justify-content-center">
                    <Button
                      v-tooltip.top="t('detail.transactions.actions.download_ticket')"
                      rounded
                      :class="slotProps.data['ticket'].trim() === '' ? 'hidden' : ''"
                      class="button button-normal"
                      @click="downloadTransactionReport(slotProps.data, TypeDocument.TICKETS)"
                    >
                      <template #icon>
                        <svg-icon name="invoice" size="16" color="#626868" />
                      </template>
                    </Button>
                  </div>
                </template>
              </Column>
              <Column
                v-if="userRole.name === Roles.admin"
                :header="t('detail.transactions.header.invoice')"
                header-class="table__header font-bold"
                class="transactions"
              >
                <template #body="slotProps">
                  <div class="flex flex-row justify-content-center">
                    <Button
                      v-tooltip.top="t('detail.transactions.actions.download_invoice')"
                      rounded
                      :class="slotProps.data['invoice'].trim() === '' ? 'hidden' : ''"
                      class="button button-start"
                      @click="downloadTransactionReport(slotProps.data, TypeDocument.INVOICES)"
                    >
                      <template #icon>
                        <svg-icon name="invoice" size="16" color="#ffffff" />
                      </template>
                    </Button>
                  </div>
                </template>
              </Column>
              <template #footer>
                <BasePaginator
                  v-model:current-page="transactionsCurrentPage"
                  v-model:rows-per-page="transactionRowsPerPage"
                  :totalRecords="totalTransactions"
                  @refresh-data="getTransactions"
                  @change="getTransactions"
                />
              </template>
              <template #expansion="slotProps">
                <div class="flex flex-row p-1 h-2rem justify-content-evenly align-content-center">
                  <div class="flex flex-column">
                    <div class="flex">
                      <svg-icon name="clock" size="18" />
                      <span class="font-bold ml-2"
                        >{{ t('detail.transactions.header.completionDate') }}:</span
                      >
                      <span class="ml-2">{{
                        new Date(slotProps['data']['createdAt']).toLocaleString()
                      }}</span>
                    </div>
                  </div>
                  <div class="flex flex-column">
                    <div class="flex">
                      <svg-icon name="contract" size="18" />
                      <span class="font-bold ml-2"
                        >{{ t('detail.transactions.header.contract') }}:</span
                      >
                      <span class="ml-2">'N/A'</span>
                    </div>
                  </div>
                  <div class="flex flex-column h-5rem justify-content-between">
                    <div class="flex">
                      <svg-icon name="reimbursable" color="#EA2839" size="18"></svg-icon>
                      <span class="font-bold ml-2">{{
                        t('detail.transactions.header.refund')
                      }}</span>
                      <span class="ml-2">{{
                        `${slotProps['data']['reimbursable']} ${CURRENCIES.euro}`
                      }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </DataTable>
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  </div>
  <LocationCreationDialog
    v-model:visible="activeUpdateLocationDialog"
    updating
    :updated-location="location"
    :updated-location-id="locationId"
    :toasting="toast"
    @refresh-locations="() => getLocation()"
  />
  <StationCreationDialog
    v-model:visible="activeCreationDialog"
    :location-id="locationId"
    :location-coords="[locationLat, locationLon]"
    :rates
    :toasting="toast"
    @refresh-stations="() => getNewStation()"
  />
  <StationCreationDialog
    v-model:visible="activeUpdateDialog"
    updating
    :updated-station="updatedStation"
    :updated-station-id="updatedStationId"
    :station-status="stationStatus"
    :rates
    :toasting="toast"
    @refresh-stations="() => getStations()"
  />
  <BasePushNotification group="success" icon-name="success" color="#00DB7F" />
  <BasePushNotification group="error" icon-name="error" color="#EA2839" />
</template>

<style scoped lang="scss">
::v-deep(.p-inputswitch-checked ::before) {
  background-color: var(--action-activate) !important;
}

::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

/* Stations table */

::v-deep(.table__charger) {
  width: 11%;
}

::v-deep(.table__model) {
  width: 10%;
}

::v-deep(.table__alias) {
  width: 18%;
}

::v-deep(.table__rate) {
  width: 15%;
}

::v-deep(.table__connectors) {
  width: 5%;
}

::v-deep(.table__energy) {
  width: 20%;
}

::v-deep(.table__status) {
  width: 27%;
}

::v-deep(.table__actions) {
  width: 5%;
}

/* Transactions table */

::v-deep(.table__expander) {
  width: 4%;
}

::v-deep(.table__cost) {
  width: 8%;
}

::v-deep(.table__start) {
  width: 13%;
}

::v-deep(.table__duration) {
  width: 10%;
}

::v-deep(.table__rfid) {
  width: 8%;
}

::v-deep(.table__energy--transactions) {
  width: 12%;
}
::v-deep(.table__station) {
  width: 18%;
}
::v-deep(.table__connection) {
  width: 5%;
}
::v-deep(.table__cause) {
  width: 20%;
}
.energy-saved {
  border-color: var(--green) !important;
}
</style>
