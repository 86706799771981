export enum RouteNamespace {
  alerts = 'alerts',
  cancelReservation = 'cancel-reservation',
  card = 'card',
  cards = 'cards',
  changeAvailability = 'change-availability',
  chargers = 'chargers',
  connectors = 'connectors',
  connectorsTypes = 'connector-types',
  contracts = 'contracts',
  createPassword = 'auth/create-password',
  customer = 'customer',
  dashboard = 'dashboard',
  default = '/login',
  emails = 'emails',
  forgotPassword = 'auth/forgot-password',
  location = 'location',
  locations = 'locations',
  login = 'auth/login',
  names = 'names',
  noAuth = 'not authorized',
  mailPassword = 'auth/mail-password',
  ocpp = 'ocpp',
  organization = 'organization',
  organizations = 'organizations',
  profile = 'profile',
  transaction = 'transaction',
  transactions = 'transactions',
  rates = 'rates',
  refreshToken = 'auth/refresh-tokens',
  remoteStartTransaction = 'remote-start-transaction',
  remoteStopTransaction = 'remote-stop-transaction',
  reset = 'reset',
  reserveNow = 'reserve-now',
  resetPassword = 'auth/reset-password',
  roles = 'roles',
  settings = 'settings',
  station = 'station',
  stations = 'stations',
  stats = 'stats',
  sum = 'sum',
  unlockConnector = 'unlock-connector',
  user = 'user',
  users = 'users',
  vendors = 'vendors',
  files = 'files',
  uploadCSVLocations = 'locations/uploads'
}
