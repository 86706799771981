<script setup lang="ts">
import { ref } from 'vue'
import { t } from '@/common/i18n'

const searchText = defineModel<string>('searchText')

const emit = defineEmits<{
  (e: 'search'): void
}>()

const isClearActive = ref(false)

const querySearch = () => {
  if (searchText.value) {
    isClearActive.value = true
    emit('search')
  }
}

const clearSearch = () => {
  searchText.value = ''
  isClearActive.value = false
  emit('search')
}
</script>

<template>
  <InputGroup
    class="input-group w-16rem"
    :pt="{
      root: {
        class: ['border-300']
      }
    }"
  >
    <InputText
      v-model="searchText"
      type="text"
      :placeholder="t('search')"
      :pt="{
        root: {
          class: ['no-focus-border-right', 'border-300', 'border-right-none']
        }
      }"
      @keyup.enter="querySearch"
    />
    <Button
      v-if="isClearActive"
      icon="pi pi-times"
      severity="secondary"
      variant="text"
      :pt="{
        root: {
          class: ['border-300', 'border-left-none', 'border-right-none', 'bg-white']
        }
      }"
      @click="clearSearch"
    />
    <Button
      icon="pi pi-search"
      severity="secondary"
      :pt="{
        root: {
          class: ['border-300']
        }
      }"
      @click="querySearch"
    />
  </InputGroup>
</template>

<style scoped lang="scss">
/* input-group added for extra specificity due to theme... */
.input-group .no-focus-border-right:focus {
  border-right: none !important;
}
</style>
