<script setup lang="ts">
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import BaseGeoLocalization from '@/components/ui/BaseGeoLocalization.vue'
import { type Contract, type Localization, Roles } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import router from '@/router'
import { ApiService } from '@/services/ApiService'
import { useApi } from '@/stores/api/api'
import { useAuthStore } from '@/stores/auth'
import { capitalizeString } from '@/utils/capitalize'

const { updatedContract, updatedContractId, updating, toasting } = defineProps<{
  updatedContract?: Partial<Contract>
  updatedContractId?: string
  updating?: boolean
  toasting: ToastServiceMethods
}>()
const visible = defineModel<boolean>('visible')

const emit = defineEmits<{
  (e: 'refreshContracts'): void
}>()

const { loading } = storeToRefs(useApi())
const { fetchContractEmails } = useApi()
const { role: userRole } = storeToRefs(useAuthStore())
const route = useRoute()

const schema = toTypedSchema(
  object({
    reference: string()
      .max(10, ({ max }) => t('validation.max', { max }))
      .transform((value) => value.toUpperCase())
      .required(() => t('validation.required')),
    name: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    address: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .default(''),
    city: string()
      .max(40, ({ max }) => t('validation.max', { max }))
      .transform((value) => capitalizeString(value))
      .default(''),
    country: string()
      .max(25, ({ max }) => t('validation.max', { max }))
      .transform((value) => capitalizeString(value))
      .default(''),
    description: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .default(''),
    email: string()
      .email(() => t('validation.isEmail'))
      .max(100, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    identityNumber: string()
      .max(25, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    phone: string()
      .max(25, ({ max }) => t('validation.max', { max }))
      .default(''),
    postcode: string()
      .max(25, ({ max }) => t('validation.max', { max }))
      .default(''),
    province: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .transform((value) => capitalizeString(value))
      .default('')
  })
)

const { defineField, handleSubmit, resetForm, errors, meta } = useForm({
  validationSchema: schema
})

const [reference] = defineField('reference')
const [name] = defineField('name')
const [address] = defineField('address')
const [city] = defineField('city')
const [country] = defineField('country')
const [email] = defineField('email')
const [identityNumber] = defineField('identityNumber')
const [phone] = defineField('phone')
const [postcode] = defineField('postcode')
const [province] = defineField('province')

const modifyAddress = (input: string) => {
  address.value = input
}

const updateAddress = (e: Localization) => {
  address.value = e.address_line1
  city.value = e.city
  country.value = e.country
  postcode.value = e.postcode
  province.value = e.county
}

const onSubmit = handleSubmit(async (values) => {
  try {
    loading.value = true
    if (updatedContractId) {
      await ApiService.updateEntity<Contract>(RouteNamespace.contracts, updatedContractId, {
        ...values
      })
    } else {
      await ApiService.createEntity<Contract>(RouteNamespace.contracts, { ...values })
    }

    await fetchContractEmails()

    toasting.add({
      group: 'success',
      severity: 'success',
      summary: updating
        ? t('detail.contract.notifications.updateSuccess')
        : t('detail.contract.notifications.createSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
    toasting.add({
      group: 'error',
      severity: 'error',
      summary: updating
        ? t('detail.contract.notifications.updateError')
        : t('detail.contract.notifications.createError'),
      life: 3000
    })
  } finally {
    visible.value = false
    emit('refreshContracts')
    loading.value = false
  }
})
const handleCancel = () => {
  visible.value = false
}
const resetParams = () => {
  if (route.query.active) router.replace({ query: { ...route.query, active: undefined } })
}
watch(visible, () => {
  resetForm({ values: updatedContract })
})
</script>

<template>
  {{ '' /* TODO: Error message layout integration */ }}
  <BaseDialog
    v-model:visible="visible"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
    @hide="resetParams"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
        <div v-if="updatedContract" class="flex flex-row">
          <p class="p-dialog-title mr-1">{{ t('detail.contract.actions.update') }} -</p>
          <p class="p-dialog-title font-family-light font-italic">
            {{ updatedContract['name'] || '' }}
          </p>
        </div>
        <p v-else class="p-dialog-title">{{ t('detail.contract.actions.create') }}</p>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="contract" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-row justify-content-between p-0 col-12">
        <div class="field col-3">
          <label class="font-family-light required" for="reference">{{
            t('detail.contract.header.reference')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="edit" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="reference"
              id="reference"
              type="text"
              aria-describedby="reference-help"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.reference"
              :placeholder="t('detail.contract.dialog.placeholder.reference')"
            />
          </IconField>
          <small id="name-help" class="p-error">
            {{ errors.name }}
          </small>
        </div>
        <div class="field col-5">
          <label class="font-family-light required" for="name">{{
            t('detail.contract.header.name')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="contract" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="name"
              id="name"
              type="text"
              aria-describedby="name-help"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.name"
              :placeholder="t('detail.contract.dialog.placeholder.name')"
            />
          </IconField>
          <small id="name-help" class="p-error">
            {{ errors.name }}
          </small>
        </div>
        <div class="field col-4">
          <label class="font-family-light required" for="identityNumber">{{
            t('detail.contract.header.identityNumber')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="nif" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="identityNumber"
              id="identityNumber"
              type="text"
              aria-describedby="identityNumber-help"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.identityNumber"
            />
          </IconField>
          <small id="identityNumber-help" class="p-error">
            {{ errors.identityNumber }}
          </small>
        </div>
      </div>
      <div class="flex flex-row p-0 col-12">
        <div class="field col-12">
          <BaseGeoLocalization
            :address="updatedContract ? updatedContract.address : ''"
            :disabled="userRole.name === Roles.support"
            :errors="errors.address"
            :placeholder="t('detail.location.dialog.placeholder.address')"
            @modify-address="modifyAddress"
            @select-address="updateAddress"
          />
          <small id="address-help" class="p-error">
            {{ errors.address }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between p-0 col-12">
        <div class="field col-6">
          <label class="font-family-light" for="city">{{ t('detail.contract.header.city') }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="city" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="city"
              id="city"
              aria-describedby="city-help"
              type="text"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.city"
              :placeholder="t('detail.contract.dialog.placeholder.city')"
            />
          </IconField>
          <small id="city-help" class="p-error">
            {{ errors.city }}
          </small>
        </div>
        <div class="field col-6">
          <label class="font-family-light" for="province">{{
            t('detail.contract.header.province')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="province" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="province"
              id="province"
              aria-describedby="province-help"
              type="text"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.province"
              :placeholder="t('detail.contract.dialog.placeholder.province')"
            />
          </IconField>
          <small id="province-help" class="p-error">
            {{ errors.province }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between p-0 col-12">
        <div class="field col-6">
          <label class="font-family-light" for="postcode">{{
            t('detail.contract.header.postcode')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="postcode" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="postcode"
              id="postcode"
              aria-describedby="postcode-help"
              type="text"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.postcode"
              :placeholder="t('detail.contract.dialog.placeholder.postcode')"
            />
          </IconField>
          <small id="postcode-help" class="p-error">
            {{ errors.postcode }}
          </small>
        </div>
        <div class="field col-6">
          <label class="font-family-light" for="country">{{
            t('detail.contract.header.country')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="country" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="country"
              id="country"
              aria-describedby="country-help"
              type="text"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.country"
              :placeholder="t('detail.contract.dialog.placeholder.country')"
            />
          </IconField>
          <small id="country-help" class="p-error">
            {{ errors.country }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between p-0 col-12">
        <div class="field col-6">
          <label class="font-family-light required" for="email">{{
            t('detail.contract.header.email')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="mail" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="email"
              id="email"
              aria-describedby="email-help"
              type="text"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.email"
              :placeholder="t('detail.contract.dialog.placeholder.email')"
            />
          </IconField>
          <small id="email-help" class="p-error">
            {{ errors.email }}
          </small>
        </div>
        <div class="field col-6">
          <label class="font-family-light" for="phone">{{
            t('detail.contract.header.phone')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="phone" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="phone"
              id="phone"
              aria-describedby="phone-help"
              type="text"
              :disabled="userRole.name === Roles.support"
              :invalid="!!errors.phone"
              :placeholder="t('detail.contract.dialog.placeholder.phone')"
            />
          </IconField>
          <small id="phone-help" class="p-error">
            {{ errors.phone }}
          </small>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog
          @cancel="handleCancel"
          @confirm="onSubmit"
          remove
          :disabled="!meta.valid || userRole.name === Roles.support"
        />
      </div>
    </template>
  </BaseDialog>
</template>
