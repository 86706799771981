<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import clock from '@/assets/lottie/clock.json'
import { i18n, t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import OrganizationCreationDialog from '@/components/organizations/OrganizationCreationDialog.vue'
import OrganizationImageDialog from '@/components/organizations/OrganizationImageDialog.vue'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BasePaginator from '@/components/ui/BasePaginator.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import BaseSearchField from '@/components/ui/BaseSearchField.vue'
import AppTopbar from '@/layout/AppTopbar.vue'
import { Roles } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import type {
  Organization,
  OrganizationResponse
} from '@/models/domain/organization/api/Organization'
import { UnitOfMeasure } from '@/models/ocpp/enums'
import router from '@/router'
import { useApi } from '@/stores/api/api'
import { useAuthStore } from '@/stores/auth'
import { ApiService } from '@/services/ApiService'
import { CURRENCIES } from '@/utils/currencies.ts'

const { loading } = storeToRefs(useApi())
const popup = ref()
const updatedOrganization = ref<Partial<Organization>>()
const activeCreationDialog = ref<boolean>(false)
const activeImageDialog = ref<boolean>(false)
const activeUpdateDialog = ref<boolean>(false)
const organizations = ref<Organization[]>([])
const totalRecords = ref<number>(0)
const rowsPerPage = ref<number>(10)
const currentPage = ref<number>(0)
const { role: userRole } = storeToRefs(useAuthStore())
const toast = useToast()

const searchText = ref('')

const getOrganizations = async () => {
  try {
    loading.value = true

    let url = `${RouteNamespace.organizations}?limit=${rowsPerPage.value}&offset=${currentPage.value}`

    if (searchText.value.length > 0) {
      url += `&search=${encodeURIComponent(searchText.value.trim().toLowerCase())}`
    }

    const response = await ApiService.readAllEntities<OrganizationResponse>(url)

    organizations.value = response['organizations']
    totalRecords.value = response['totalRecords']
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
  }
}
const openOrganizationImageDialog = (newOrganization: Organization) => {
  updatedOrganization.value = newOrganization
  activeImageDialog.value = true
}
const handleCreate = () => {
  activeCreationDialog.value = true
}
const handleUpdate = (row: Partial<Organization>) => {
  updatedOrganization.value = {
    id: row.id,
    address: row.address,
    city: row.city,
    contract: row.contract,
    country: row.country,
    email: row.email,
    imageProfile: row.imageProfile,
    name: row.name,
    phone: row.phone,
    postcode: row.postcode,
    province: row.province
  }
  activeUpdateDialog.value = true
}
const actionsRemoveOrganization = async (organizationId: string) => {
  try {
    const payload = {
      isDeleted: true
    }
    await ApiService.updateEntityWithResources(
      `${RouteNamespace.organizations}/${organizationId}`,
      payload
    )
    toast.add({
      group: 'success',
      severity: 'success',
      summary: i18n.global.t('detail.organization.notifications.deleteSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
    toast.add({
      group: 'error',
      severity: 'error',
      summary: i18n.global.t('detail.organization.notifications.deleteError'),
      life: 3000
    })
  } finally {
    await getOrganizations()
  }
}
const handleRemove = async (event: Event, organizationId: string) => {
  popup.value.showConfirmPopup(
    event,
    async () => {
      loading.value = true
      await actionsRemoveOrganization(organizationId)
      loading.value = false
    },
    undefined
  )
}

onMounted(async () => {
  try {
    loading.value = true
    await getOrganizations()
    //open modal dialog from query param
    if (router.currentRoute.value.query.active) {
      activeCreationDialog.value = true
    }
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
  }
})
</script>
<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex">
            <svg-icon name="organization" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.organizations') }}</span>
          </div>
          <Button
            v-if="userRole.name !== Roles.support"
            v-tooltip.top="t('detail.organization.actions.create')"
            class="button button-normal ml-3"
            rounded
            @click="handleCreate"
          >
            <template #icon>
              <svg-icon name="add" size="18" color="#626868" />
            </template>
          </Button>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex flex-row flex-wrap column-gap-4">
        <BaseSearchField v-model:search-text="searchText" @search="getOrganizations" />
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      :value="organizations"
      :rows="rowsPerPage"
      dataKey="id"
      scrollable
      sortField="name"
      :sortOrder="1"
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.organization.notFound')" />
      </template>
      <Column
        field="name"
        :header="t('detail.organization.header.name')"
        class="table__name"
        sortable
      >
        <template #body="slotProps">
          <Tag class="capitalize" :value="slotProps.data['name']" rounded severity="warn" />
        </template>
      </Column>
      <Column
        field="billing"
        :header="t('detail.organization.header.billing')"
        header-class="font-bold"
        class="table__billing"
      >
        <template #body="slotProps">
          {{ `${slotProps.data['totalTransactions']} ${CURRENCIES.euro}` }}
        </template>
      </Column>
      <Column
        field="energy"
        :header="t('detail.organization.header.energy_supplied')"
        header-class="font-bold"
        class="table__energy"
      >
        <template #body="slotProps">
          {{ `${slotProps.data['totalEnergy']} ${UnitOfMeasure.KWH}` }}
        </template>
      </Column>
      <Column
        field="users"
        :header="t('detail.organization.header.users')"
        header-class="font-bold"
        class="table__users"
      >
        <template #body="slotProps">
          <Tag :value="slotProps.data['totalUsers']" rounded />
        </template>
      </Column>
      <Column
        field="cards"
        :header="t('detail.organization.header.rfidCards')"
        header-class="font-bold"
        class="table__cards"
      >
        <template #body="slotProps">
          <Tag :value="slotProps.data['totalCards']" rounded />
        </template>
      </Column>
      <Column
        :header="t('detail.header.actions')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              v-tooltip.top="t('detail.organization.actions.update')"
              rounded
              class="button button-normal mr-2"
              @click="handleUpdate(slotProps.data)"
            >
              <template #icon>
                <svg-icon
                  :name="userRole.name === Roles.support ? 'vision' : 'edit'"
                  size="16"
                  color="#626868"
                />
              </template>
            </Button>
            <BaseConfirmDeletePopup ref="popup" />
            <Button
              v-if="userRole.name !== Roles.support"
              v-tooltip.top="t('detail.organization.actions.delete')"
              icon="pi pi-trash"
              rounded
              class="button button-remove"
              @click="handleRemove($event, slotProps.data['id'])"
            >
              <template #icon>
                <svg-icon name="trash" size="16" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <template #footer>
        <BasePaginator
          v-model:current-page="currentPage"
          v-model:rows-per-page="rowsPerPage"
          :totalRecords
          @refresh-data="getOrganizations"
          @change="getOrganizations"
        />
      </template>
    </DataTable>
  </div>
  <OrganizationCreationDialog
    v-model:visible="activeUpdateDialog"
    updating
    :updated-organization="updatedOrganization"
    :updated-organization-id="updatedOrganization?.id"
    :toasting="toast"
    @refresh-organizations="getOrganizations()"
  />
  <OrganizationCreationDialog
    v-model:visible="activeCreationDialog"
    :toasting="toast"
    @add-image="openOrganizationImageDialog"
    @refresh-organizations="getOrganizations()"
  />
  <OrganizationImageDialog
    v-model:visible="activeImageDialog"
    :updated-organization="updatedOrganization"
    :toasting="toast"
  />
  <BasePushNotification group="success" icon-name="success" color="#00DB7F" />
  <BasePushNotification group="error" icon-name="error" color="#EA2839" />
</template>

<style scoped>
::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__name) {
  width: 16%;
}

::v-deep(.table__role) {
  width: 14%;
}

::v-deep(.table__billing) {
  width: 14%;
}

::v-deep(.table__energy) {
  width: 16%;
}

::v-deep(.table__users) {
  width: 11%;
}

::v-deep(.table__cards) {
  width: 13%;
}

::v-deep(.table__actions) {
  width: 16%;
}
</style>
