<script setup lang="ts">
import { ref } from 'vue'
import { i18n, t } from '@/common/i18n'
import clock from '@/assets/lottie/clock.json'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BasePaginator from '@/components/ui/BasePaginator.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import AppTopbar from '@/layout/AppTopbar.vue'
import { FilterMatchMode } from '@primevue/core/api'
import { storeToRefs } from 'pinia'
import { useApi } from '@/stores/api/api.ts'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import type { Charger, Vendor } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace.ts'
import { useToast } from 'primevue/usetoast'
import ChargerCreationDialog from '@/components/chargers/ChargerCreationDialog.vue'
import ChargerImageDialog from '@/components/chargers/ChargerImageDialog.vue'
import VendorCreationDialog from '@/components/vendors/VendorCreationDialog.vue'
import VendorImageDialog from '@/components/vendors/VendorImageDialog.vue'
import { ApiService } from '@/services/ApiService.ts'

const { chargers, loading, vendors } = storeToRefs(useApi())
const { fetchChargers, fetchVendors } = useApi()
const toast = useToast()
const popup = ref()

const filters = ref({
  global: { value: undefined, matchMode: FilterMatchMode.CONTAINS }
})

const updatedCharger = ref<Charger>()
const updatedVendor = ref<Vendor>()

const activeChargerCreationDialog = ref<boolean>(false)
const activeChargerImageDialog = ref<boolean>(false)
const activeChargerUpdateDialog = ref<boolean>(false)
const activeVendorCreationDialog = ref<boolean>(false)
const activeVendorImageDialog = ref<boolean>(false)
const activeVendorUpdateDialog = ref<boolean>(false)

const currentChargersPage = ref<number>(1)
const currentVendorsPage = ref<number>(1)
const rowsPerChargersPage = ref<number>(10)
const rowsPerVendorsPage = ref<number>(10)
const tabIndex = ref<string>('0')

const openChargerImageDialog = (newCharger: Charger) => {
  updatedCharger.value = newCharger
  activeChargerImageDialog.value = true
}
const openVendorImageDialog = (newVendor: Vendor) => {
  updatedVendor.value = newVendor
  activeVendorImageDialog.value = true
}

// TODO: Deleting a charger doesn't immediately update their respective stores.
// After removing a charger, fetchChargers retrieves the deleted entity.
// The backend updates the entity but still includes it in the following fetch.
// TODO: Can happen when creating/updating as well.
const actionsRemoveCharger = async (chargerId: string) => {
  try {
    loading.value = true
    const payload = {
      isDeleted: true
    }
    await ApiService.updateEntityWithResources(`${RouteNamespace.chargers}/${chargerId}`, payload)
    toast.add({
      group: 'success',
      severity: 'success',
      summary: i18n.global.t('detail.charger.notifications.deleteSuccess'),
      life: 3000
    })
    await fetchChargers()
  } catch (error) {
    console.error('Error occurred while removing charger:', error)
    toast.add({
      group: 'error',
      severity: 'error',
      summary: i18n.global.t('detail.charger.notifications.deleteError'),
      life: 3000
    })
  } finally {
    loading.value = false
  }
}
const actionsRemoveVendor = async (vendorId: string) => {
  try {
    loading.value = true
    const payload = {
      isDeleted: true
    }
    await ApiService.updateEntityWithResources(`${RouteNamespace.vendors}/${vendorId}`, payload)
    toast.add({
      group: 'success',
      severity: 'success',
      summary: i18n.global.t('detail.vendor.notifications.deleteSuccess'),
      life: 3000
    })
    await fetchVendors()
  } catch (error) {
    console.error('Error occurred while removing vendor:', error)
    toast.add({
      group: 'error',
      severity: 'error',
      summary: i18n.global.t('detail.vendor.notifications.deleteError'),
      life: 3000
    })
  } finally {
    loading.value = false
  }
}

const handleChargerCreate = () => {
  activeChargerCreationDialog.value = true
}
const handleChargerRemove = (event: Event, chargerId: string) => {
  popup.value.showConfirmPopup(
    event,
    async () => {
      loading.value = true
      await actionsRemoveCharger(chargerId)
      loading.value = false
    },
    undefined
  )
}
const handleChargerUpdate = (row: Charger) => {
  updatedCharger.value = {
    id: row.id,
    connectors: row.connectors,
    connectorType: row.connectorType,
    image: row.image,
    name: row.name,
    vendor: row.vendor
  }
  activeChargerUpdateDialog.value = true
}

const handleVendorCreate = () => {
  activeVendorCreationDialog.value = true
}
const handleVendorRemove = (event: Event, vendorId: string) => {
  popup.value.showConfirmPopup(
    event,
    async () => {
      loading.value = true
      await actionsRemoveVendor(vendorId)
      loading.value = false
    },
    undefined
  )
}
const handleVendorUpdate = (row: Vendor) => {
  updatedVendor.value = {
    id: row.id,
    image: row.image,
    name: row.name
  }
  activeVendorUpdateDialog.value = true
}
</script>

<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex">
            <svg-icon name="vendor-charger" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.chargers') }}</span>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <IconField icon-position="left" class="mr-2">
        <InputIcon class="pi pi-search"></InputIcon>
        <InputText
          v-model="filters['global'].value"
          :placeholder="t('search')"
          type="text"
          :pt="{
            root: {
              class: ['border-300']
            }
          }"
        />
      </IconField>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <Tabs v-model:value="tabIndex">
      <TabList>
        <Tab value="0">
          <div class="flex justify-content-between align-items-center">
            <svg-icon name="station" size="24" />
            <span>{{ t('detail.charger.chargers') }}</span>
            <Button
              v-tooltip.top="t('detail.charger.actions.create')"
              rounded
              class="button button-normal ml-3"
              @click="handleChargerCreate"
            >
              <template #icon>
                <i class="font-bold pi pi-plus text-sm" />
              </template>
            </Button>
          </div>
        </Tab>
        <Tab value="1">
          <div class="flex justify-content-between align-items-center">
            <svg-icon name="vendor-charger" size="24" />
            <span>{{ t('detail.vendor.vendors') }}</span>
            <Button
              v-tooltip.top="t('detail.vendor.actions.create')"
              rounded
              class="button button-normal ml-3"
              @click="handleVendorCreate"
            >
              <template #icon>
                <i class="font-bold pi pi-plus text-sm" />
              </template>
            </Button>
          </div>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel value="0">
          <div class="flex">
            <div class="col-12">
              <DataTable
                v-model:filters="filters"
                :value="chargers"
                dataKey="id"
                scrollable
                :globalFilterFields="['name', 'vendor.name']"
                :rows="rowsPerChargersPage"
              >
                <template #empty>
                  <BaseLottieAnimation :icon="clock" :label="t('detail.charger.notFound')" />
                </template>
                <Column field="name" class="table__charger-image">
                  <template #body="slotProps">
                    <Avatar
                      v-if="slotProps.data.image"
                      class="avatar"
                      size="large"
                      :image="slotProps.data.image"
                    />
                    <Avatar v-else class="avatar" size="large" icon="pi pi-bolt" />
                  </template>
                </Column>
                <Column
                  field="name"
                  :header="t('detail.charger.model')"
                  header-class="font-bold"
                  class="table__charger-name"
                >
                  <template #body="slotProps">
                    <Tag class="uppercase" :value="slotProps.data.name" severity="warn" />
                  </template>
                </Column>
                <Column
                  field="vendor.name"
                  :header="t('detail.charger.vendor')"
                  header-class="font-bold"
                  class="table__charger-vendor-name"
                >
                  <template #body="slotProps">
                    <Tag class="uppercase" :value="slotProps.data.vendor.name" severity="warn" />
                  </template>
                </Column>
                <Column
                  :header="t('detail.header.actions')"
                  header-class="table__header font-bold"
                  class="table__actions"
                >
                  <template #body="slotProps">
                    <div class="flex flex-row justify-content-center">
                      <Button
                        class="button button-normal mr-2"
                        v-tooltip.top="t('detail.charger.actions.update')"
                        rounded
                        @click="handleChargerUpdate(slotProps.data)"
                      >
                        <template #icon>
                          <svg-icon name="edit" size="20" color="#626868" />
                        </template>
                      </Button>
                      <Button
                        class="button button-remove ml-2"
                        v-tooltip.top="t('detail.charger.actions.delete')"
                        rounded
                        @click="handleChargerRemove($event, slotProps.data['id'])"
                      >
                        <template #icon>
                          <svg-icon name="trash" size="18" />
                        </template>
                      </Button>
                      <BaseConfirmDeletePopup ref="popup" />
                    </div>
                  </template>
                </Column>
                <template #footer>
                  <BasePaginator
                    v-model:current-page="currentChargersPage"
                    v-model:rows-per-page="rowsPerChargersPage"
                    :totalRecords="chargers.length"
                  />
                </template>
              </DataTable>
            </div>
          </div>
        </TabPanel>
        <TabPanel value="1">
          <div class="flex">
            <div class="col-12">
              <DataTable
                v-model:filters="filters"
                :value="vendors"
                dataKey="id"
                scrollable
                :globalFilterFields="['name']"
                :rows="rowsPerVendorsPage"
              >
                <template #empty>
                  <BaseLottieAnimation :icon="clock" :label="t('detail.vendor.notFound')" />
                </template>
                <Column field="name" class="table__charger-image">
                  <template #body="slotProps">
                    <Avatar
                      v-if="slotProps.data.image"
                      class="avatar"
                      size="large"
                      :image="slotProps.data.image"
                    />
                    <Avatar v-else class="avatar" size="large" icon="pi pi-tag" />
                  </template>
                </Column>
                <Column
                  field="name"
                  :header="t('detail.vendor.vendor')"
                  header-class="font-bold"
                  class="table__vendor-name"
                >
                  <template #body="slotProps">
                    <Tag class="uppercase" :value="slotProps.data.name" severity="warn" />
                  </template>
                </Column>
                <Column
                  :header="t('detail.header.actions')"
                  header-class="table__header font-bold"
                  class="table__actions"
                >
                  <template #body="slotProps">
                    <div class="flex flex-row justify-content-center">
                      <Button
                        class="button button-normal mr-2"
                        v-tooltip.top="t('detail.vendor.actions.update')"
                        rounded
                        @click="handleVendorUpdate(slotProps.data)"
                      >
                        <template #icon>
                          <svg-icon name="edit" size="20" color="#626868" />
                        </template>
                      </Button>
                      <Button
                        class="button button-remove ml-2"
                        v-tooltip.top="t('detail.vendor.actions.delete')"
                        rounded
                        @click="handleVendorRemove($event, slotProps.data['id'])"
                      >
                        <template #icon>
                          <svg-icon name="trash" size="18" />
                        </template>
                      </Button>
                      <BaseConfirmDeletePopup ref="popup" />
                    </div>
                  </template>
                </Column>
                <template #footer>
                  <BasePaginator
                    v-model:current-page="currentVendorsPage"
                    v-model:rows-per-page="rowsPerVendorsPage"
                    :totalRecords="vendors.length"
                  />
                </template>
              </DataTable>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  </div>
  <ChargerCreationDialog
    v-model:visible="activeChargerUpdateDialog"
    updating
    :updated-charger="updatedCharger"
    :updated-charger-id="updatedCharger?.id"
    :toasting="toast"
    @refresh-chargers="fetchChargers()"
  />
  <ChargerCreationDialog
    v-model:visible="activeChargerCreationDialog"
    :toasting="toast"
    @add-image="openChargerImageDialog"
    @refresh-chargers="fetchChargers()"
  />
  <ChargerImageDialog
    v-model:visible="activeChargerImageDialog"
    :updated-charger="updatedCharger"
    :toasting="toast"
    @refresh-chargers="fetchChargers()"
  />
  <VendorCreationDialog
    v-model:visible="activeVendorUpdateDialog"
    updating
    :updated-vendor="updatedVendor"
    :updated-vendor-id="updatedVendor?.id"
    :toasting="toast"
    @refresh-vendors="fetchVendors()"
  />
  <VendorCreationDialog
    v-model:visible="activeVendorCreationDialog"
    :toasting="toast"
    @add-image="openVendorImageDialog"
    @refresh-vendors="fetchVendors()"
  />
  <VendorImageDialog
    v-model:visible="activeVendorImageDialog"
    :updated-vendor="updatedVendor"
    :toasting="toast"
    @refresh-vendors="fetchVendors()"
  />
  <BasePushNotification group="success" icon-name="success" color="#00DB7F" />
  <BasePushNotification group="error" icon-name="error" color="#EA2839" />
</template>

<style scoped lang="scss">
::v-deep(.table__header) > div > span {
  margin: 0 auto;
}
::v-deep(.table__charger-image) {
  width: 10%;
}
::v-deep(.table__charger-name) {
  width: 37.5%;
}

::v-deep(.table__charger-vendor-name) {
  width: 37.5%;
}

::v-deep(.table__vendor-name) {
  width: 75%;
}

::v-deep(.table__actions) {
  width: 25%;
}

::v-deep(.avatar img) {
  object-fit: fill;
  border-radius: var(--p-avatar-border-radius);
}
</style>
