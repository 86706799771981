<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import router from '@/router'
import { t } from '@/common/i18n'
import { useApi } from '@/stores/api/api'
import { useAuthStore } from '@/stores/auth'
import { ApiService } from '@/services/ApiService'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import BaseGeoLocalization from '@/components/ui/BaseGeoLocalization.vue'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { type Localization, type Location, Roles } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { capitalizeString } from '@/utils/capitalize'

const { updatedLocation, updatedLocationId, updating, toasting } = defineProps<{
  updatedLocation?: Partial<Location>
  updatedLocationId?: string
  updating?: boolean
  toasting: ToastServiceMethods
}>()

const visible = defineModel<boolean>('visible')

const emit = defineEmits<{
  (e: 'refreshLocations'): void
}>()

const { loading, organizationEmails } = storeToRefs(useApi())
const { role: userRole, organizations: userOrganizations } = useAuthStore()
const route = useRoute()

// TODO: Error messages i18n
const schema = toTypedSchema(
  object({
    address: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.address')),
    city: string()
      .max(40, ({ max }) => t('validation.max', { max }))
      .transform((value) => capitalizeString(value))
      .required(() => t('validation.required')),
    country: string()
      .max(25, ({ max }) => t('validation.max', { max }))
      .transform((value) => capitalizeString(value))
      .required(() => t('validation.required')),
    description: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .default(''),
    energySources: string()
      .max(500, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required'))
      .test('is-json', t('validation.json'), (value) => {
        try {
          JSON.parse(value)
          return true
        } catch (e) {
          return false
        }
      }),
    name: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    organizationId: string()
      .uuid()
      .when((_, schema) =>
        userRole.name === Roles.admin ||
        (userRole.name === Roles.manager && userOrganizations.length > 1)
          ? schema.required(() => t('validation.required')).default('')
          : schema.notRequired()
      ),
    postcode: string()
      .max(25, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    province: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .transform((value) => capitalizeString(value))
      .required(() => t('validation.required'))
  })
)

const { defineField, handleSubmit, resetForm, errors, meta } = useForm({
  validationSchema: schema
})

const [address] = defineField('address')
const [city] = defineField('city')
const [country] = defineField('country')
const [description] = defineField('description')
const [energySources] = defineField('energySources')
const [name] = defineField('name')
const [organizationId] = defineField('organizationId')
const [postcode] = defineField('postcode')
const [province] = defineField('province')

const lat = ref<number>()
const lon = ref<number>()

const modifyAddress = () => {
  address.value = ''
}

const updateAddress = (e: Localization) => {
  address.value = e.address_line1
  city.value = e.city
  country.value = e.country
  postcode.value = e.postcode
  province.value = e.county
  lat.value = e.lat
  lon.value = e.lon
}

const onSubmit = handleSubmit(async (values) => {
  try {
    loading.value = true
    if (updatedLocationId) {
      await ApiService.updateEntity(RouteNamespace.locations, updatedLocationId, {
        ...values,
        organizationId: values.organizationId ? values.organizationId : userOrganizations[0].id,
        energySources: JSON.parse(values.energySources),
        lat: lat.value,
        lon: lon.value
      })
    } else {
      await ApiService.createEntity(RouteNamespace.locations, {
        ...values,
        organizationId: values.organizationId ? values.organizationId : userOrganizations[0].id,
        energySources: JSON.parse(values.energySources),
        lat: lat.value,
        lon: lon.value
      })
    }
    toasting.add({
      group: 'success',
      severity: 'success',
      summary: updating
        ? t('detail.location.notifications.updateSuccess')
        : t('detail.location.notifications.createSuccess'),
      life: 3000
    })
    emit('refreshLocations')
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
    toasting.add({
      group: 'error',
      severity: 'error',
      summary: updating
        ? t('detail.location.notifications.updateError')
        : t('detail.location.notifications.createError'),
      life: 3000
    })
  } finally {
    visible.value = false
    loading.value = false
  }
})
const handleCancel = () => {
  visible.value = false
}
const resetParams = () => {
  if (route.query.active) router.replace({ query: { ...route.query, active: undefined } })
}
watch(visible, () => {
  resetForm({
    values: {
      ...updatedLocation,
      organizationId: updatedLocation?.organization?.id,
      energySources: JSON.stringify(updatedLocation?.energySources)
    }
  })
})
</script>

<template>
  {{ '' /* TODO: Error message layout integration */ }}
  <BaseDialog
    v-model:visible="visible"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
    @hide="resetParams"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
        <p class="p-dialog-title">
          {{ updating ? t('detail.location.actions.update') : t('detail.location.actions.create') }}
        </p>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="location" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div
        v-if="
          userRole.name === Roles.admin ||
          (userRole.name === Roles.manager && userOrganizations.length > 1)
        "
        class="flex flex-row"
      >
        <div class="field col-12">
          <label for="organizationId" class="font-family-light required">{{
            t('detail.location.dialog.organization')
          }}</label>
          <Select
            v-model="organizationId"
            id="organizationId"
            aria-describedby="organizationId-help"
            class="h-3rem align-items-center"
            filter
            optionLabel="name"
            optionValue="id"
            :options="userRole.name === Roles.admin ? organizationEmails : userOrganizations"
            :invalid="!!errors.organizationId"
            :emptyMessage="t('detail.organization.notFound')"
            :emptyFilterMessage="t('detail.organization.notFound')"
            :placeholder="t('detail.location.dialog.placeholder.organization')"
            :pt="{
              item: ({ context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#9E9E9E" />
              </div>
            </template>
          </Select>
          <small id="organizationId-help" class="p-error">
            {{ errors.organizationId }}
          </small>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="field col-12">
          <label for="name" class="font-family-light required">{{
            t('detail.location.header.name')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="location-name" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="name"
              id="name"
              type="text"
              aria-describedby="name-help"
              :disabled="userRole.name === Roles.support || userRole.name === Roles.viewer"
              :invalid="!!errors.name"
              :placeholder="t('detail.location.dialog.placeholder.name')"
            />
          </IconField>
          <small id="name-help" class="p-error">
            {{ errors.name }}
          </small>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="field col-12">
          <BaseGeoLocalization
            :address="updatedLocation ? updatedLocation.address : ''"
            :errors="errors.address"
            :placeholder="t('detail.location.dialog.placeholder.address')"
            required
            :disabled="userRole.name === Roles.support || userRole.name === Roles.viewer"
            @modify-address="modifyAddress"
            @select-address="updateAddress"
          />
        </div>
      </div>
      <div class="flex flex-row">
        <div class="field col-12 md:col-6">
          <label class="font-family-light required" for="city">{{
            t('detail.organization.header.city')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="city" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="city"
              id="city"
              aria-describedby="city-help"
              type="text"
              :disabled="userRole.name === Roles.support || userRole.name === Roles.viewer"
              :invalid="!!errors.city"
              :placeholder="t('detail.location.dialog.placeholder.city')"
            />
          </IconField>
          <small id="city-help" class="p-error">
            {{ errors.city }}
          </small>
        </div>
        <div class="field col-6 md:col-6">
          <label class="font-family-light required" for="province">{{
            t('detail.organization.header.province')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="province" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="province"
              id="province"
              aria-describedby="province-help"
              type="text"
              :disabled="userRole.name === Roles.support || userRole.name === Roles.viewer"
              :invalid="!!errors.province"
              :placeholder="t('detail.location.dialog.placeholder.province')"
            />
          </IconField>
          <small id="province-help" class="p-error">
            {{ errors.province }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between">
        <div class="field col-12 md:col-6">
          <label class="font-family-light required" for="postcode">{{
            t('detail.organization.header.postcode')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="postcode" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="postcode"
              id="postcode"
              aria-describedby="postcode-help"
              type="text"
              :disabled="userRole.name === Roles.support || userRole.name === Roles.viewer"
              :invalid="!!errors.postcode"
              :placeholder="t('detail.location.dialog.placeholder.postcode')"
            />
          </IconField>
          <small id="postcode-help" class="p-error">
            {{ errors.postcode }}
          </small>
        </div>
        <div class="field col-12 md:col-6">
          <label class="font-family-light required" for="country">{{
            t('detail.organization.header.country')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="country" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="country"
              id="country"
              aria-describedby="country-help"
              type="text"
              :disabled="userRole.name === Roles.support || userRole.name === Roles.viewer"
              :invalid="!!errors.country"
              :placeholder="t('detail.location.dialog.placeholder.country')"
            />
          </IconField>
          <small id="country-help" class="p-error">
            {{ errors.country }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between">
        <div class="field col-12">
          <label class="font-family-light" for="description">{{
            t('detail.location.dialog.description')
          }}</label>
          <Textarea
            v-model="description"
            id="description"
            rows="5"
            cols="30"
            autoResize
            aria-describedby="description-help"
            type="text"
            :disabled="userRole.name === Roles.support || userRole.name === Roles.viewer"
            :invalid="!!errors.description"
            :placeholder="t('detail.location.dialog.placeholder.description')"
          />
          <small id="description-help" class="p-error">
            {{ errors.description }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between">
        <div class="field col-12">
          <label class="font-family-light required" for="energySources">{{
            t('detail.location.dialog.placeholder.energySources')
          }}</label>
          <Textarea
            v-model="energySources"
            id="energySources"
            rows="5"
            cols="30"
            autoResize
            aria-describedby="energySources-help"
            type="text"
            :disabled="updating"
            :invalid="!!errors.energySources"
          />
          <small id="energySources-help" class="p-error">
            {{ errors.energySources }}
          </small>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog
          @cancel="handleCancel"
          @confirm="onSubmit"
          remove
          :disabled="
            !meta.valid || userRole.name === Roles.support || userRole.name === Roles.viewer
          "
        />
      </div>
    </template>
  </BaseDialog>
</template>
